<ion-header
  [translucent]="true"
  class="modalHeader"
  scrollX="true"
  scrollY="true"
>
  <ion-toolbar mode="md">
    <ion-title class="ion-no-margin">
      <ion-text class="ion-text-center" color="primary">
        <h2>Product Options</h2>
      </ion-text>
    </ion-title>
    <ion-buttons class="backButton" mode="ios">
      <ion-button (click)="close()">
        <ion-icon color="secondary" name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  *ngIf="productDoc && loading"
  class="modalContent"
  scrollX="true"
  scrollY="true"
>
  <form [formGroup]="productConfigForm" class="modalForm">
    <!-- Price Tier -->
    <ion-list>
      <!-- Define the radio group, each radio item sends it's value to this element when it's selected -->
      <!--      That value is then sent to the form group variable -->
      <ion-radio-group formControlName="priceTier" mode="md" name="priceTier">
        <ion-list-header>
          <ion-label>Select a Price Tier</ion-label>
        </ion-list-header>
        <!-- Loop through each price, adding a new item for each -->
        <!--      Index is defined since we'll need it to figure out which tier was selected -->
        <ion-item *ngFor="let price of productDoc.prices; let i= index">
          <!-- Determine which label to load based on whether price has a note -->
          <ion-label *ngIf="!price.note">${{price.price}}</ion-label>
          <ion-label *ngIf="price.note">
            {{price.note}} &mdash; ${{price.price}}
          </ion-label>
          <!-- This is the part of the item that is clicked (circle on material design, checkmark on iOS) -->
          <!--     Our index from eariler is passed to the value of each radio button and is therefore set to the formControl's value when selected -->
          <ion-radio [value]="i" slot="start"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>

    <!-- Color -->
    <ion-list
      *ngIf="productDoc.colorLinks && productDoc.colorLinks.length >= 1"
    >
      <ion-radio-group formControlName="color" mode="md" name="color">
        <ion-list-header>
          <ion-label>Select a Color Option</ion-label>
        </ion-list-header>

        <ion-item
          *ngFor="let colorImage of productDoc.colorLinks; let
                    i=
                    index"
        >
          <ion-label>
            <img alt="" class="colorImage" src="{{colorImage}}"/>
          </ion-label>

          <ion-radio [value]="i" slot="start"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>

    <!-- Carrier -->
    <ion-list *ngIf="productDoc.note">
      <ion-list-header>
        <ion-label>Optional Add-on</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-label> {{productDoc.note}} </ion-label>
        <ion-checkbox
          [value]="productDoc.note"
          formControlName="carrier"
          mode="md"
          slot="start"
        >
        </ion-checkbox>
      </ion-item>
    </ion-list>

    <ion-item>
      <ion-label>Quantity:</ion-label>
      <ion-input
        formControlName="count"
        max="10"
        min="1"
        type="number"
        value="1"
      ></ion-input>
    </ion-item>
  </form>
  <!-- Add to Cart -->
  <ion-button
    (click)="submitForm()"
    *ngIf="!adding"
    [disabled]="!productConfigForm.valid"
    color="primary" expand="block">
    {{edit ? 'Update' : 'Add to'}} Cart
  </ion-button>
  <div *ngIf="adding" class="ion-text-center ion-padding-top">
    <ion-spinner
      color="primary">
    </ion-spinner>
  </div>
</ion-content>
