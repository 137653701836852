<ion-header
  [translucent]="true"
  scrollX="true"
  scrollY="true"
  class="modalHeader"
>
  <ion-toolbar mode="md">
    <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2>
          Add a new {{addAdminForm.controls.userType.value === 'superAdmin' ?
          'Super Admin' : 'Admin'}}
        </h2>
      </ion-text>
    </ion-title>
    <ion-buttons slot="start" mode="ios" class="backButton">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close" color="secondary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="addAdminForm" class="modalForm">
    <ion-item>
      <ion-label position="fixed">
        <h2>Name</h2>
      </ion-label>
      <ion-input
        type="text"
        placeholder="John Doe"
        formControlName="displayName"
      >
      </ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="fixed">
        <h2>Email</h2>
      </ion-label>
      <ion-input
        type="email"
        placeholder="admin@email.com"
        formControlName="email"
      >
      </ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="fixed">
        <h2>Password</h2>
      </ion-label>
      <ion-input
        type="password"
        placeholder="********"
        formControlName="password"
        minlength="6"
      >
      </ion-input>
    </ion-item>

    <ion-item>
      <ion-label>
        <h2>Permission Level</h2>
      </ion-label>
      <ion-select
        okText="Set"
        cancelText="Cancel"
        (ionChange)="updateOpenStatus($event)"
        placeholder="User Type"
        formControlName="userType"
      >
        <ion-select-option value="admin"> Admin </ion-select-option>
        <ion-select-option value="superAdmin"> Super Admin </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-button
      color="primary"
      expand="block"
      [disabled]="!addAdminForm.valid"
      (click)="submitForm()"
      class="button"
    >
      <ion-icon name="person-add-outline"></ion-icon>
      Add New {{addAdminForm.controls.userType.value === 'superAdmin' ? 'Super
      Admin' : 'Admin'}}
    </ion-button>
  </form>
</ion-content>
