<ion-card *ngIf="loc" class="store-card">
  <ion-header style="background-color: #1e2023; padding: 8px">
    <ion-card-title style="color: white">{{loc.shortName}}</ion-card-title>
  </ion-header>
  <ion-card-content>
    <ion-grid class="ion-no-padding location-item">
      <ion-row>
        <ion-col size="auto">
          <ion-icon class="icon-primary" name="location"></ion-icon>
        </ion-col>
        <ion-col class="ion-padding-start">
          <a [href]="loc.mapsUrl" target="_blank">{{loc.streetAddress}}, {{loc.suite}}, <br *ngIf="!win.isMobile && !win.isTablet">{{loc.state}}, {{loc.zipCode}}</a>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="ion-no-padding location-item">
      <ion-row>
        <ion-col size="auto">
          <ion-icon class="icon-primary" name="call-outline"></ion-icon>
        </ion-col>
        <ion-col class="ion-padding-start">
         <a href="tel:{{loc.phone}}">{{map.phoneFormat(loc.phone)}}</a>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="ion-no-padding location-item">
      <ion-row class="ion-no-padding">
        <ion-col size="auto">
          <ion-icon class="icon-primary" name="time-outline"></ion-icon>
        </ion-col>
        <ion-col class="ion-padding-start">{{loc.weekdayHours.openTime}} - {{loc.weekdayHours.closeTime}}
          <span [ngClass]="isOpen ? '' : 'color-red'">({{isOpen ? 'Open' : 'Closed'}})</span>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="ion-no-padding location-item">
      <ion-row class="ion-no-padding">
        <ion-col size="auto">
          <ion-icon class="icon-primary" name="shuffle"></ion-icon>
        </ion-col>
        <ion-col class="ion-padding-start">{{loc.distance}} Miles Away</ion-col>
      </ion-row>
    </ion-grid>

    <div (click)="selectStore(loc)" class="select-btn" color="primary">Select Store</div>
  </ion-card-content>
</ion-card>
