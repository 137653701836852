import {Component, OnInit} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {ModalController, NavParams} from '@ionic/angular';
import {Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {
  Category,
  DynamicForms,
  FileObject,
  Product,
  ProductDetail,
  ProductPrice,
} from 'src/app/models/catalog.model';
import {CatalogService} from 'src/app/services/catalog/catalog.service';
import {StorageService} from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.page.html',
  styleUrls: ['./edit-category.page.scss'],
})
export class EditCategoryPage implements OnInit {

  // Store the category sent by the caller page and it's matching product documents
  category: Category;
  productDocs: Observable<Product[]>;
  categoryConfigForm: FormGroup;

  // category image file global vars
  destroyCatiImage$: Subject<null> = new Subject();
  catiImageFile: File[];
  uploadCatiImagePercent = 0;
  isUploading = false;
  previewCatiImageUrl: string | ArrayBuffer;

  // Store the dynamically generated reactive forms for each type of product info that can be updated
  productBaseForms: { [docId: string]: DynamicForms } = {};
  productDetailForms: { [docId: string]: DynamicForms } = {};
  productImageForms: { [docId: string]: DynamicForms } = {};
  productColorForms: { [docId: string]: DynamicForms } = {};
  productPriceForms: { [docId: string]: DynamicForms } = {};
  productNewPriceForms: { [docId: string]: DynamicForms } = {};
  productNewImageForms: { [docId: string]: DynamicForms } = {};
  productNewColorForms: { [docId: string]: DynamicForms } = {};

  addingPrice = false;
  addingColor = false;
  addingImage = false;
  // Empty template products to quickly add to arrays.
  emptyDetail = {
    detailName: '',
    iconLink: '',
  };
  emptyPrice = {note: '', price: ''};

  // Dev Only, remove when converted

  constructor(
    public catalogService: CatalogService,
    private readonly storageService: StorageService,
    public viewCtrl: ModalController,
    public navParams: NavParams,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.category = navParams.get('doc');
    this.productDocs = this.catalogService.productDocs$(this.category.docId);
  }

  // Dev Only, remove when converted

  // Changes the value of any key in any object, includes a length variable for strings.
  changeProp(thing, prop, val, length = null) {
    let newVal = val.children[0].value;
    if (length && newVal.length > length) {
      newVal = newVal.slice(0, length);
    }

    thing[prop] = newVal;
    val.children[0].value = newVal;
  }

  // Dev Only, remove when converted

  // Arbitrarily adds a property to any object.
  addProp(thing, prop) {
    thing.push(prop);
  }

  // Arbitrarily removes a property to any object.
  removeProp(thing, prop) {
    thing.splice(thing.indexOf(prop), 1);
  }

  onCatiImageChange(event) {
    try {
      this.catiImageFile = event.target.files;

      const reader = new FileReader();
      reader.readAsDataURL(this.catiImageFile[0]);
      reader.onload = (_event) => (this.previewCatiImageUrl = reader.result);
    } catch (err) {
      window.alert(`Couldn't set file. \n ${err}`);
    }
  }

  onProductBaseImageChange(event, docId: string) {
    try {
      this.productBaseForms[docId].images[0].file = event.target.files;

      const reader = new FileReader();
      reader.readAsDataURL(this.productBaseForms[docId].images[0].file[0]);
      reader.onload = (_event) =>
        (this.productBaseForms[docId].images[0].previewUrl = reader.result);
    } catch (err) {
      window.alert(`Couldn't set file. \n ${err}`);
    }
  }

  onProductImageChange(event, docId: string, i: number) {
    try {
      this.productImageForms[docId].images[i].file = event.target.files;

      const reader = new FileReader();
      reader.readAsDataURL(this.productImageForms[docId].images[i].file[0]);
      reader.onload = (_event) =>
        (this.productImageForms[docId].images[i].previewUrl = reader.result);
    } catch (err) {
      window.alert(`Couldn't set file. \n ${err}`);
      this.resetProductFile(docId, i);
    }
  }

  onProductColorImageChange(event, docId: string, i: number) {
    try {
      this.productColorForms[docId].images[i].file = event.target.files;

      const reader = new FileReader();
      reader.readAsDataURL(this.productColorForms[docId].images[i].file[0]);
      reader.onload = (_event) =>
        (this.productColorForms[docId].images[i].previewUrl = reader.result);
    } catch (err) {
      window.alert(`Couldn't set file. \n ${err}`);
      this.resetFile(docId, i);
    }
  }

  onProductNewImageChange(event, docId: string, i: number) {
    try {
      this.productNewImageForms[docId].images[i].file = event.target.files;

      const reader = new FileReader();
      reader.readAsDataURL(this.productNewImageForms[docId].images[i].file[0]);
      reader.onload = (_event) =>
        (this.productNewImageForms[docId].images[i].previewUrl = reader.result);
    } catch (err) {
      window.alert(`Couldn't set file. \n ${err}`);
      this.resetNewProductFile(docId, i);
    }
  }

  onProductNewColorImageChange(event, docId: string, i: number) {
    try {
      this.productNewColorForms[docId].images[i].file = event.target.files;

      const reader = new FileReader();
      reader.readAsDataURL(this.productNewColorForms[docId].images[i].file[0]);
      reader.onload = (_event) =>
        (this.productNewColorForms[docId].images[i].previewUrl = reader.result);
    } catch (err) {
      window.alert(`Couldn't set file. \n ${err}`);
      this.resetNewColorFile(docId, i);
    }
  }

  submitCatiForm() {
    if (this.categoryConfigForm.valid) {
      // Get a reference to the category doc that we want to edit
      const categoryDocRef: AngularFirestoreDocument = this.afs
        .collection('categories')
        .doc<Category>(this.category.docId);

      // Store info for new image upload if an image was uploaded
      if (this.catiImageFile) {
        const mediaFolderPath = `categoryPhotos`;
        const catiImageFile = this.catiImageFile[0];

        // Upload cover image file and get ref to it's URL
        const {downloadUrl$, uploadProgress$} =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            catiImageFile
          );

        // Handle UI during upload
        uploadProgress$
          .pipe(takeUntil(this.destroyCatiImage$))
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.isUploading = true;
              this.uploadCatiImagePercent = 0.01;
            } else {
              this.isUploading = true;
              this.uploadCatiImagePercent = uploadProgress / 100;
            }
            if (this.uploadCatiImagePercent === 1) {
              setTimeout(() => {
                // Reset for next use

                // Reset category cover image settings
                this.uploadCatiImagePercent = 0;
                this.catiImageFile = undefined;
                this.isUploading = false;
              }, 200);
            }
          });

        downloadUrl$
          .pipe(takeUntil(this.destroyCatiImage$))
          .subscribe((downloadUrl) => {
            // Add the newly uploaded cover image to the doc
            categoryDocRef
              .set(
                {
                  imageLink: downloadUrl,
                },
                {merge: true}
              )
              .then(() =>
                this.storage.refFromURL(this.category.imageLink).delete()
              );
          });
      }

      // Set the updated data to the category while maintaining unchanged data
      categoryDocRef
        .set(
          {
            title: this.categoryConfigForm.controls.title.value,
            subTitle: this.categoryConfigForm.controls.subTitle.value,
            order: this.categoryConfigForm.controls.order.value,
            hasAsp: this.categoryConfigForm.controls.hasAsp.value,
          },
          {merge: true}
        )
        .then(() => {
          this.close();
        });
    } else {
      window.alert(
        `Values aren't valid, please edit and try again \n Error Code: CFV-02`
      );
    }
  }

  submitProductBaseForm(docId: string, oldImgLink: string) {
    if (this.productBaseForms[docId].form.valid) {
      // Get a reference to the product doc that we want to edit
      const productDocRef: AngularFirestoreDocument = this.afs
        .collection('products')
        .doc<Product>(docId);

      // Store info for new image upload if an image was uploaded
      if (this.productBaseForms[docId].images[0].file) {
        const mediaFolderPath = `productPhotos`;
        const imageFile = this.productBaseForms[docId].images[0].file[0];

        // Upload primary image file and get ref to it's URL
        const {downloadUrl$, uploadProgress$} =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            imageFile
          );

        // Handle UI during upload
        uploadProgress$
          .pipe(takeUntil(this.productBaseForms[docId].images[0].destroyImage$))
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.productBaseForms[docId].images[0].isUploading = true;
              this.productBaseForms[docId].images[0].uploadPercent = 0.01;
            } else {
              this.productBaseForms[docId].images[0].isUploading = true;
              this.productBaseForms[docId].images[0].uploadPercent =
                uploadProgress / 100;
            }
            if (this.productBaseForms[docId].images[0].uploadPercent === 1) {
              setTimeout(() => {
                // Reset for next use

                // Reset product primary image settings
                this.productBaseForms[docId].images[0].uploadPercent = 0;
                this.productBaseForms[docId].images[0].file = undefined;
                this.productBaseForms[docId].images[0].isUploading = false;
              }, 200);
            }
          });

        downloadUrl$
          .pipe(takeUntil(this.productBaseForms[docId].images[0].destroyImage$))
          .subscribe((downloadUrl) => {
            // Add the newly uploaded product image to the doc
            productDocRef
              .set(
                {
                  primaryImageLink: downloadUrl,
                },
                {merge: true}
              )
              .then(() => this.storage.refFromURL(oldImgLink).delete());
          });
      }

      // Set the updated data to the product while maintaining unchanged data
      productDocRef.set(
        {
          title: this.productBaseForms[docId].form.controls.title.value,
          order: this.productBaseForms[docId].form.controls.order.value,
          note: this.productBaseForms[docId].form.controls.note.value,
        },
        {merge: true}
      );
    } else {
      window.alert(
        `Values aren't valid, please edit and try again \n Error Code: CFV-03`
      );
    }
  }

  submitProductColorForm(docId: string, previousColorLinks: string[]) {
    // Get a reference to the product doc that we want to edit
    const productDocRef: AngularFirestoreDocument = this.afs
      .collection('products')
      .doc<Product>(docId);

    const colors = [];

    Object.keys(this.productColorForms[docId].form.controls).forEach(
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (formKey) => {this.productColorForms[docId].form.controls[formKey].value !== null ? colors.push(formKey) : false;}
    );

    const colorLinks: string[] = [];
    previousColorLinks.forEach((link) => colorLinks.push(link));
    colors.forEach((color: string) => {
      // Get the uniform index for the color provided
      // eslint-disable-next-line radix
      const colorIndex = parseInt(color.split('_')[1]);

      // Store info for new image upload if an image was uploaded
      if (this.productColorForms[docId].images[colorIndex].file) {
        const mediaFolderPath = `productColors`;
        const imageFile =
          this.productColorForms[docId].images[colorIndex].file[0];

        // Upload primary image file and get ref to it's URL
        const {downloadUrl$, uploadProgress$} =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            imageFile
          );

        // Handle UI during upload
        uploadProgress$
          .pipe(
            takeUntil(
              this.productColorForms[docId].images[colorIndex].destroyImage$
            )
          )
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.productColorForms[docId].images[colorIndex].isUploading =
                true;
              this.productColorForms[docId].images[
                colorIndex
                ].uploadPercent = 0.01;
            } else {
              this.productColorForms[docId].images[colorIndex].isUploading =
                true;
              this.productColorForms[docId].images[colorIndex].uploadPercent =
                uploadProgress / 100;
            }
            if (
              this.productColorForms[docId].images[colorIndex].uploadPercent ===
              1
            ) {
              setTimeout(() => {
                // Reset for next use

                // Reset image settings
                this.productColorForms[docId].images[
                  colorIndex
                  ].uploadPercent = 0;
                this.productColorForms[docId].images[colorIndex].file =
                  undefined;
                this.productColorForms[docId].images[colorIndex].isUploading =
                  false;
              }, 200);
            }
          });

        downloadUrl$
          .pipe(
            takeUntil(
              this.productColorForms[docId].images[colorIndex].destroyImage$
            )
          )
          .subscribe((downloadUrl) => {
            // Delete the Firebase Storage file for the old iamge
            this.storage.refFromURL(previousColorLinks[colorIndex]).delete();

            // Replace old image link with the new image in storage array
            colorLinks[colorIndex] = downloadUrl;

            // Set the updated data to the product while maintaining unchanged data
            productDocRef.set(
              {
                colorLinks,
              },
              {merge: true}
            );
          });
      }
    });
  }

  submitProductImageForm(docId: string, previousImageLinks: string[]) {
    // Get a reference to the product doc that we want to edit
    const productDocRef: AngularFirestoreDocument = this.afs
      .collection('products')
      .doc<Product>(docId);

    const images = [];

    Object.keys(this.productImageForms[docId].form.controls).forEach(
      (formKey) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.productImageForms[docId].form.controls[formKey].value !== null ? images.push(formKey) : false;
      }
    );

    const imageLinks: string[] = [];
    previousImageLinks.forEach((link) => imageLinks.push(link));
    images.forEach((color: string) => {
      // Get the uniform index for the image provided
      // eslint-disable-next-line radix
      const imageIndex = parseInt(color.split('_')[1]);

      // Store info for new image upload if an image was uploaded
      if (this.productImageForms[docId].images[imageIndex].file) {
        const mediaFolderPath = `productPhotos`;
        const imageFile =
          this.productImageForms[docId].images[imageIndex].file[0];

        // Upload primary image file and get ref to it's URL
        const {downloadUrl$, uploadProgress$} =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            imageFile
          );

        // Handle UI during upload
        uploadProgress$
          .pipe(
            takeUntil(
              this.productImageForms[docId].images[imageIndex].destroyImage$
            )
          )
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.productImageForms[docId].images[imageIndex].isUploading =
                true;
              this.productImageForms[docId].images[
                imageIndex
                ].uploadPercent = 0.01;
            } else {
              this.productImageForms[docId].images[imageIndex].isUploading =
                true;
              this.productImageForms[docId].images[imageIndex].uploadPercent =
                uploadProgress / 100;
            }
            if (
              this.productImageForms[docId].images[imageIndex].uploadPercent ===
              1
            ) {
              setTimeout(() => {
                // Reset for next use

                // Reset image settings
                this.productImageForms[docId].images[
                  imageIndex
                  ].uploadPercent = 0;
                this.productImageForms[docId].images[imageIndex].file =
                  undefined;
                this.productImageForms[docId].images[imageIndex].isUploading =
                  false;
              }, 200);
            }
          });

        downloadUrl$
          .pipe(
            takeUntil(
              this.productImageForms[docId].images[imageIndex].destroyImage$
            )
          )
          .subscribe((downloadUrl) => {
            // Delete the Firebase Storage file for the old iamge
            this.storage.refFromURL(previousImageLinks[imageIndex]).delete();

            // Replace old image link with the new image in storage array
            imageLinks[imageIndex] = downloadUrl;

            // Set the updated data to the product while maintaining unchanged data
            productDocRef.set(
              {
                imageLinks,
              },
              {merge: true}
            );
          });
      }
    });
  }

  submitProductPriceForm(docId: string) {
    if (this.productBaseForms[docId].form.valid) {
      // Get a reference to the product doc that we want to edit
      const productDocRef: AngularFirestoreDocument = this.afs
        .collection('products')
        .doc<Product>(docId);

      const prices = [];
      const notes = [];
      Object.keys(this.productPriceForms[docId].form.controls).forEach(
        (formKey) => {
          if (formKey.includes('price')) {
            prices[formKey.split('_')[1]] =
              this.productPriceForms[docId].form.controls[formKey].value;
          }
          if (formKey.includes('note')) {
            notes[formKey.split('_')[1]] =
              this.productPriceForms[docId].form.controls[formKey].value;
          }
        }
      );
      const newPricing = [];
      prices.forEach((price, i) => {
        newPricing.push({
          price: price.toString(),
          note: notes[i],
        });

        // Format the price to always have 2 digits, no mater what
        const arrPrice: string[] = newPricing[i].price.split('.');
        if (arrPrice[1] === undefined || arrPrice[1].length === 0) {
          newPricing[i].price = `${newPricing[i].price}.00`;
        }
        if (arrPrice[1] !== undefined && arrPrice[1].length === 1) {
          newPricing[i].price = `${arrPrice[0]}.${arrPrice[1]}0`;
        }
        if (arrPrice[1] !== undefined && arrPrice[1].length > 2) {
          newPricing[i].price = `${arrPrice[0]}.${arrPrice[1].substring(0, 2)}`;
        }
      });

      // Sort to ensure that cheeper items are shown first
      newPricing.sort((a, b) => a.price - b.price);

      // Set the updated data to the product while maintaining unchanged data
      productDocRef.set(
        {
          prices: newPricing,
        },
        {merge: true}
      );
    } else {
      window.alert(
        `Values aren't valid, please edit and try again \n Error Code: CFV-07`
      );
    }
  }

  submitNewProductColorForm(product: Product) {
    const docId = product.docId;

    if (this.productNewColorForms[docId].form.valid) {
      // Get a reference to the product doc that we want to edit
      const productDocRef: AngularFirestoreDocument = this.afs
        .collection('products')
        .doc<Product>(docId);

      // Store info for new image upload if an image was uploaded
      if (this.productNewColorForms[docId].images[0].file) {
        const mediaFolderPath = `productColors`;
        const imageFile = this.productNewColorForms[docId].images[0].file[0];

        // Upload primary image file and get ref to it's URL
        const {downloadUrl$, uploadProgress$} =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            imageFile
          );

        // Handle UI during upload
        uploadProgress$
          .pipe(
            takeUntil(this.productNewColorForms[docId].images[0].destroyImage$)
          )
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.productNewColorForms[docId].images[0].isUploading = true;
              this.productNewColorForms[docId].images[0].uploadPercent = 0.01;
            } else {
              this.productNewColorForms[docId].images[0].isUploading = true;
              this.productNewColorForms[docId].images[0].uploadPercent =
                uploadProgress / 100;
            }
            if (
              this.productNewColorForms[docId].images[0].uploadPercent === 1
            ) {
              setTimeout(() => {
                // Reset image settings
                this.productNewColorForms[docId].images[0].uploadPercent = 0;
                this.productNewColorForms[docId].images[0].file = undefined;
                this.productNewColorForms[docId].images[0].isUploading = false;
              }, 200);
            }
          });

        downloadUrl$
          .pipe(
            takeUntil(this.productNewColorForms[docId].images[0].destroyImage$)
          )
          .subscribe((downloadUrl) => {
            // Create a local instance of the existing colors that were passed in the function call
            const colors = product.colorLinks;
            colors.push(downloadUrl); // Pass the new color link as a new element in the local instance of the color array

            // Set the updated data to the product while maintaining unchanged data
            productDocRef
              .set(
                {
                  colorLinks: colors,
                },
                {merge: true}
              )
              .then(() => {
                this.addingColor = false;
                this.productNewColorForms[docId].form.reset();
                this.productNewColorForms[docId].form.markAsPristine();
                this.productNewColorForms[docId].form.markAsUntouched();
              });
          });
      }
    } else {
      window.alert(
        `Values aren't valid, please edit and try again \n Error Code: CFV-10`
      );
    }
  }

  submitNewProductImageForm(product: Product) {
    const docId = product.docId;

    if (this.productNewImageForms[docId].form.valid) {
      // Get a reference to the product doc that we want to edit
      const productDocRef: AngularFirestoreDocument = this.afs
        .collection('products')
        .doc<Product>(docId);

      // Store info for new image upload if an image was uploaded
      if (this.productNewImageForms[docId].images[0].file) {
        const mediaFolderPath = `productPhotos`;
        const imageFile = this.productNewImageForms[docId].images[0].file[0];

        // Upload primary image file and get ref to it's URL
        const {downloadUrl$, uploadProgress$} =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            imageFile
          );

        // Handle UI during upload
        uploadProgress$
          .pipe(
            takeUntil(this.productNewImageForms[docId].images[0].destroyImage$)
          )
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.productNewImageForms[docId].images[0].isUploading = true;
              this.productNewImageForms[docId].images[0].uploadPercent = 0.01;
            } else {
              this.productNewImageForms[docId].images[0].isUploading = true;
              this.productNewImageForms[docId].images[0].uploadPercent =
                uploadProgress / 100;
            }
            if (
              this.productNewImageForms[docId].images[0].uploadPercent === 1
            ) {
              setTimeout(() => {
                // Reset image settings
                this.productNewImageForms[docId].images[0].uploadPercent = 0;
                this.productNewImageForms[docId].images[0].file = undefined;
                this.productNewImageForms[docId].images[0].isUploading = false;
              }, 200);
            }
          });

        downloadUrl$
          .pipe(
            takeUntil(this.productNewImageForms[docId].images[0].destroyImage$)
          )
          .subscribe((downloadUrl) => {
            // Create a local instance of the existing colors that were passed in the function call
            const images = product.imageLinks;
            images.push(downloadUrl); // Pass the new color link as a new element in the local instance of the color array

            // Set the updated data to the product while maintaining unchanged data
            productDocRef
              .set(
                {
                  imageLinks: images,
                },
                {merge: true}
              )
              .then(() => {
                this.addingImage = false;
                this.productNewImageForms[docId].form.reset();
                this.productNewImageForms[docId].form.markAsPristine();
                this.productNewImageForms[docId].form.markAsUntouched();
              });
          });
      }
    } else {
      window.alert(
        `Values aren't valid, please edit and try again \n Error Code: CFV-10`
      );
    }
  }

  submitNewProductPriceForm(product: Product) {
    const docId = product.docId;
    const prices = product.prices;

    if (this.productNewPriceForms[docId].form.valid) {
      // Get a reference to the product doc that we want to edit
      const productDocRef: AngularFirestoreDocument = this.afs
        .collection('products')
        .doc<Product>(docId);

      const newPricing = {
        price:
          this.productNewPriceForms[
            docId
            ].form.controls.newPrice.value.toString(),
        note: this.productNewPriceForms[docId].form.controls.newNote.value,
      };

      // Format the price to always have 2 digits, no mater what
      const arrPrice: string[] = newPricing.price.split('.');
      if (arrPrice[1] === undefined || arrPrice[1].length === 0) {
        newPricing.price = `${newPricing.price}.00`;
      }
      if (arrPrice[1] !== undefined && arrPrice[1].length === 1) {
        newPricing.price = `${arrPrice[0]}.${arrPrice[1]}0`;
      }
      if (arrPrice[1] !== undefined && arrPrice[1].length > 2) {
        newPricing.price = `${arrPrice[0]}.${arrPrice[1].substring(0, 2)}`;
      }

      // Add the new price tier to the existing array of prices
      prices.push(newPricing);

      // Sort to ensure that cheeper items are shown first
      prices.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

      // Set the updated data to the product while maintaining unchanged data
      productDocRef
        .set(
          {
            prices,
          },
          {merge: true}
        )
        .then(() => {
          this.addingPrice = false;
          this.productNewPriceForms[product.docId].form.reset();
        });
    } else {
      window.alert(
        `Values aren't valid, please edit and try again \n Error Code: CFV-09`
      );
    }
  }

  removeProductImage(
    docId: string,
    previousImageLinks: string[],
    sentIndex: number
  ) {
    // Get a reference to the product doc that we want to edit
    const productDocRef: AngularFirestoreDocument = this.afs
      .collection('products')
      .doc<Product>(docId);

    const images = [];
    Object.keys(this.productImageForms[docId].form.controls).forEach(
      (formKey) => {
        const formKeyIndex = formKey.split('_')[1];
        // Only adds the image for consideration if it's the one we wish to remove or if it's one that's been modified
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions,max-len
        this.productImageForms[docId].form.controls[formKey].value !== null || parseFloat(formKeyIndex) === sentIndex ? images.push(formKey) : false;
      }
    );

    // Create a new storage var for the image links and paste the sent ones into it
    //    This is needed for comparing previous file to new file since we need to perserve the integerty of the old array
    const imageLinks: string[] = [];
    previousImageLinks.forEach((link) => imageLinks.push(link));

    images.forEach((image: string) => {
      // Get the uniform index for the image provided
      // eslint-disable-next-line radix
      const imageIndex = parseInt(image.split('_')[1]);

      if (imageIndex === sentIndex) {
        // Delete the Firebase Storage file for the removed image
        this.storage.refFromURL(previousImageLinks[sentIndex]).delete();

        // Remove the dead link (due to file being deleted) from the array of images on the product
        imageLinks.splice(sentIndex, 1);

        // Remove reference to the deleted image from the storage form and the images form
        this.productImageForms[docId].form.removeControl(
          `imageLink_${sentIndex}`
        );
        this.productImageForms[docId].images.splice(sentIndex, 1);

        // Set the updated data to the product while maintaining unchanged data
        productDocRef.set(
          {
            imageLinks,
          },
          {merge: true}
        );

        return;
      }

      // Store info for new image upload if an image was uploaded
      if (this.productImageForms[docId].images[imageIndex].file) {
        const mediaFolderPath = `productPhotos`;
        const imageFile =
          this.productImageForms[docId].images[imageIndex].file[0];

        // Upload primary image file and get ref to it's URL
        const {downloadUrl$, uploadProgress$} =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            imageFile
          );

        // Handle UI during upload
        uploadProgress$
          .pipe(
            takeUntil(
              this.productImageForms[docId].images[imageIndex].destroyImage$
            )
          )
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.productImageForms[docId].images[imageIndex].isUploading =
                true;
              this.productImageForms[docId].images[
                imageIndex
                ].uploadPercent = 0.01;
            } else {
              this.productImageForms[docId].images[imageIndex].isUploading =
                true;
              this.productImageForms[docId].images[imageIndex].uploadPercent =
                uploadProgress / 100;
            }
            if (
              this.productImageForms[docId].images[imageIndex].uploadPercent ===
              1
            ) {
              setTimeout(() => {
                // Reset for next use

                // Reset image settings
                this.productImageForms[docId].images[
                  imageIndex
                  ].uploadPercent = 0;
                this.productImageForms[docId].images[imageIndex].file =
                  undefined;
                this.productImageForms[docId].images[imageIndex].isUploading =
                  false;
              }, 200);
            }
          });

        downloadUrl$
          .pipe(
            takeUntil(
              this.productImageForms[docId].images[imageIndex].destroyImage$
            )
          )
          .subscribe((downloadUrl) => {
            // Delete the Firebase Storage file for the old iamge
            this.storage.refFromURL(previousImageLinks[imageIndex]).delete();

            // Replace old image link with the new image in storage array
            imageLinks[imageIndex] = downloadUrl;

            // Set the updated data to the product while maintaining unchanged data
            productDocRef.set(
              {
                imageLinks,
              },
              {merge: true}
            );
          });
      }
    });
  }

  removeProductColor(
    docId: string,
    previousColorLinks: string[],
    sentIndex: number
  ) {
    // Get a reference to the product doc that we want to edit
    const productDocRef: AngularFirestoreDocument = this.afs
      .collection('products')
      .doc<Product>(docId);

    const colors = [];
    Object.keys(this.productColorForms[docId].form.controls).forEach(
      (formKey) => {
        const formKeyIndex = formKey.split('_')[1];
        // Only adds the color for consideration if it's the one we wish to remove or if it's one that's been modified
        // eslint-disable-next-line max-len,@typescript-eslint/no-unused-expressions
        this.productColorForms[docId].form.controls[formKey].value !== null || parseFloat(formKeyIndex) === sentIndex ? colors.push(formKey) : false;
      }
    );

    // Create a new storage var for the color links and paste the sent ones into it
    //    This is needed for comparing previous file to new file since we need to perserve the integerty of the old array
    const colorLinks: string[] = [];
    previousColorLinks.forEach((link) => colorLinks.push(link));

    colors.forEach((color: string) => {
      // Get the uniform index for the color provided
      // eslint-disable-next-line radix
      const colorIndex = parseInt(color.split('_')[1]);

      if (colorIndex === sentIndex) {
        // Delete the Firebase Storage file for the removed image
        this.storage.refFromURL(previousColorLinks[sentIndex]).delete();

        // Remove the dead link (due to file being deleted) from the array of colors on the product
        colorLinks.splice(sentIndex, 1);

        // Remove reference to the deleted color from the storage form and the images form
        this.productColorForms[docId].form.removeControl(
          `colorLink_${sentIndex}`
        );
        this.productColorForms[docId].images.splice(sentIndex, 1);

        // Set the updated data to the product while maintaining unchanged data
        productDocRef.set(
          {
            colorLinks,
          },
          {merge: true}
        );

        return;
      }

      // Store info for new image upload if an image was uploaded
      if (this.productColorForms[docId].images[colorIndex].file) {
        const mediaFolderPath = `productColors`;
        const imageFile =
          this.productColorForms[docId].images[colorIndex].file[0];

        // Upload primary image file and get ref to it's URL
        const {downloadUrl$, uploadProgress$} =
          this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            imageFile
          );

        // Handle UI during upload
        uploadProgress$
          .pipe(
            takeUntil(
              this.productColorForms[docId].images[colorIndex].destroyImage$
            )
          )
          .subscribe((uploadProgress) => {
            if (uploadProgress === 0) {
              this.productColorForms[docId].images[colorIndex].isUploading =
                true;
              this.productColorForms[docId].images[
                colorIndex
                ].uploadPercent = 0.01;
            } else {
              this.productColorForms[docId].images[colorIndex].isUploading =
                true;
              this.productColorForms[docId].images[colorIndex].uploadPercent =
                uploadProgress / 100;
            }
            if (
              this.productColorForms[docId].images[colorIndex].uploadPercent ===
              1
            ) {
              setTimeout(() => {
                // Reset for next use

                // Reset image settings
                this.productColorForms[docId].images[
                  colorIndex
                  ].uploadPercent = 0;
                this.productColorForms[docId].images[colorIndex].file =
                  undefined;
                this.productColorForms[docId].images[colorIndex].isUploading =
                  false;
              }, 200);
            }
          });

        downloadUrl$
          .pipe(
            takeUntil(
              this.productColorForms[docId].images[colorIndex].destroyImage$
            )
          )
          .subscribe((downloadUrl) => {
            // Delete the Firebase Storage file for the old iamge
            this.storage.refFromURL(previousColorLinks[colorIndex]).delete();

            // Replace old image link with the new image in storage array
            colorLinks[colorIndex] = downloadUrl;

            // Set the updated data to the product while maintaining unchanged data
            productDocRef.set(
              {
                colorLinks,
              },
              {merge: true}
            );
          });
      }
    });
  }

  removeProductDetail(sentIndex: number, product: Product) {
    const docId = product.docId;
    const newDetails = [];

    product.details.forEach((detail, i) => {
      // Send the details that aren't marked for deletion to a new storage var
      if (sentIndex !== i) {
        newDetails.push(detail);
      }
      // If the element being deleted has an image, delete the image as well
      if (sentIndex === i && detail.iconLink) {
        this.storage.refFromURL(detail.iconLink).delete();
      }
    });

    // Sort to ensure that items with an icon are shown first
    newDetails.sort((a, b) =>
      a.hasOwnProperty('iconLink') ? -1 : b.hasOwnProperty('iconLink') ? 1 : 0
    );

    // Get a reference to the product doc that we want to edit
    const productDocRef: AngularFirestoreDocument = this.afs
      .collection('products')
      .doc<Product>(docId);

    // Set the updated data to the product while maintaining unchanged data
    productDocRef.set(
      {
        details: newDetails,
      },
      {merge: true}
    );
  }

  removeProductPrice(docId: string, sentIndex: number) {
    // This section validates that all of the price fields are valid but skips validation on the one being deleted
    let invalid = false;
    Object.keys(this.productPriceForms[docId].form.controls).forEach(
      (controlKey, keyIndex) => {
        if (!controlKey.includes('price')) {
          return;
        }
        if (sentIndex === keyIndex) {
          return;
        }
        if (!this.productPriceForms[docId].form.controls[controlKey].valid) {
          invalid = true;
          return;
        }
      }
    );
    if (invalid) {
      window.alert(
        `Values aren't valid, please edit and try again \n Error Code: CFV-08`
      );
      return;
    }

    // Get a reference to the product doc that we want to edit
    const productDocRef: AngularFirestoreDocument = this.afs
      .collection('products')
      .doc<Product>(docId);

    const prices = [];
    const notes = [];
    Object.keys(this.productPriceForms[docId].form.controls).forEach(
      (formKey) => {
        if (formKey.includes('price')) {
          prices[formKey.split('_')[1]] =
            this.productPriceForms[docId].form.controls[formKey].value;
        }
        if (formKey.includes('note')) {
          notes[formKey.split('_')[1]] =
            this.productPriceForms[docId].form.controls[formKey].value;
        }
      }
    );
    const newPricing = [];
    prices.forEach((price, i) => {
      newPricing.push({
        price: price.toString(),
        note: notes[i],
      });

      // Format the price to always have 2 digits, no mater what
      const arrPrice: string[] = newPricing[i].price.split('.');
      if (arrPrice[1] === undefined || arrPrice[1].length === 0) {
        newPricing[i].price = `${newPricing[i].price}.00`;
      }
      if (arrPrice[1] !== undefined && arrPrice[1].length === 1) {
        newPricing[i].price = `${arrPrice[0]}.${arrPrice[1]}0`;
      }
      if (arrPrice[1] !== undefined && arrPrice[1].length > 2) {
        newPricing[i].price = `${arrPrice[0]}.${arrPrice[1].substring(0, 2)}`;
      }
    });

    // Remove the specified price
    newPricing.splice(sentIndex, 1);
    // Sort to ensure that cheeper items are shown first
    newPricing.sort((a, b) => a.price - b.price);

    // Set the updated data to the product while maintaining unchanged data
    productDocRef.set(
      {
        prices: newPricing,
      },
      {merge: true}
    );
  }

  resetProductFile(docId: string, i: number) {
    // Reset the image storage values
    this.productImageForms[docId].images[i] = {
      destroyImage$: new Subject(),
      file: undefined,
      uploadPercent: 0,
      isUploading: false,
      previewUrl: '',
    };

    // Reset the value of the form
    this.productImageForms[docId].form.controls[
      `imageLink_${i}`
      ].markAsPristine();
    this.productImageForms[docId].form.controls[`imageLink_${i}`].reset();
  }

  resetFile(docId: string, i: number) {
    // Reset the image storage values
    this.productColorForms[docId].images[i] = {
      destroyImage$: new Subject(),
      file: undefined,
      uploadPercent: 0,
      isUploading: false,
      previewUrl: '',
    };

    // Reset the value of the form
    this.productColorForms[docId].form.controls[
      `colorLink_${i}`
      ].markAsPristine();
    this.productColorForms[docId].form.controls[`colorLink_${i}`].reset();
  }

  resetNewProductFile(docId: string, i: number) {
    // Reset the image storage values
    this.productNewImageForms[docId].images[i] = {
      destroyImage$: new Subject(),
      file: undefined,
      uploadPercent: 0,
      isUploading: false,
      previewUrl: '',
    };

    // Reset the value of the form
    this.productNewImageForms[docId].form.controls.newImageLink.markAsPristine();
    this.productNewImageForms[docId].form.controls.newImageLink.reset();
  }

  resetNewColorFile(docId: string, i: number) {
    // Reset the image storage values
    this.productNewColorForms[docId].images[i] = {
      destroyImage$: new Subject(),
      file: undefined,
      uploadPercent: 0,
      isUploading: false,
      previewUrl: '',
    };

    // Reset the value of the form
    this.productNewColorForms[docId].form.controls.newColorLink.markAsPristine();
    this.productNewColorForms[docId].form.controls.newColorLink.reset();
  }

  // Use a button to toggle a section dropped. inButtonFlex accounts for a third nesting of the button instead of the normal two.
  toggleDropped(buttonToToggle, inButtonFlex = false) {
    buttonToToggle = inButtonFlex
      ? buttonToToggle.parentElement.parentElement.parentElement
      : buttonToToggle.parentElement.parentElement;
    buttonToToggle.classList.toggle('dropped');
  }

  // Check if an element has the 'dropped' class.
  isDropped(elementId) {
    if (document.getElementById(elementId).classList.contains('dropped')) {
      return true;
    }
    return false;
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    // Reactive form defs for editing the provided category's info
    this.categoryConfigForm = this.formBuilder.group({
      title: new FormControl(this.category.title, [Validators.required]),
      subTitle: new FormControl(this.category.subTitle, [Validators.required]),
      order: new FormControl(this.category.order, [
        Validators.required,
        Validators.min(0),
      ]),
      hasAsp: new FormControl(
        this.category.hasAsp ? this.category.hasAsp : false,
        [Validators.required]
      ),
      imageLink: new FormControl(null, [
        Validators.pattern(/^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g),
      ]),
    });

    // Build and store reactive forms for each product doc's base info (name, note, etc)
    this.productDocs.subscribe((products) => {
      products.forEach((product) => {
        // Set inital declarations where needed so that undefined errors don't occur when assigning next level of data
        this.productBaseForms[product.docId] = {};
        this.productDetailForms[product.docId] = {};
        this.productImageForms[product.docId] = {};
        this.productPriceForms[product.docId] = {};
        this.productColorForms[product.docId] = {};
        this.productNewPriceForms[product.docId] = {};
        this.productNewColorForms[product.docId] = {};
        this.productNewImageForms[product.docId] = {};

        this.productBaseForms[product.docId].images = [];
        this.productDetailForms[product.docId].images = [];
        this.productImageForms[product.docId].images = [];
        this.productColorForms[product.docId].images = [];
        this.productNewColorForms[product.docId].images = [];
        this.productNewImageForms[product.docId].images = [];

        // Build the form for base level product info and
        //  store it in the DynamicForms object for this series of fields
        this.productBaseForms[product.docId].form = this.formBuilder.group({
          title: new FormControl(product.title, [Validators.required]),
          note: new FormControl(product.note ? product.note : '', [
            Validators.required,
          ]),
          order: new FormControl(product.order, [
            Validators.required,
            Validators.min(0),
          ]),
          primaryImageLink: new FormControl(null, [
            Validators.pattern(/^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g),
          ]),
        });

        // AHHH

        // Dynamically build a reactive form structure for each product's prices/price notes
        //  Runs a second loop within the loop that we're currently in to add each price to the form
        this.productDetailForms[product.docId].form = this.formBuilder.group(
          {}
        );
        product.details.forEach((details, i) => {
          this.productDetailForms[product.docId].form.addControl(
            `detailName_${i}`,
            new FormControl(details.detailName, [
              Validators.required,
              Validators.min(0),
            ])
          );
          // Only add an image to form and image objects if there's an image for that array elem in the product
          if (details.iconLink) {
            this.productDetailForms[product.docId].images[i] = {
              destroyImage$: new Subject(),
              file: undefined,
              uploadPercent: 0,
              isUploading: false,
              previewUrl: '',
            };

            this.productDetailForms[product.docId].form.addControl(
              `iconLink_${i}`,
              new FormControl(null, [
                Validators.pattern(
                  /^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g
                ),
                Validators.required,
              ])
            );
          }
        });

        // AHH

        // Dynamically build a reactive form structure for each product's prices/price notes
        //  Runs a second loop within the loop that we're currently in to add each price to the form
        this.productPriceForms[product.docId].form = this.formBuilder.group({});
        product.prices.forEach((price, i) => {
          this.productPriceForms[product.docId].form.addControl(
            `price_${i}`,
            new FormControl(price.price, [
              Validators.required,
              Validators.min(0),
            ])
          );
          this.productPriceForms[product.docId].form.addControl(
            `note_${i}`,
            new FormControl(price.note ? price.note : '')
          );
        });

        // Build the form that's for adding an additional price field to a product and
        //  store it in the DynamicForms object for this series of fields
        this.productNewPriceForms[product.docId].form = this.formBuilder.group({
          newPrice: new FormControl('', [
            Validators.required,
            Validators.min(0),
          ]),
          newNote: new FormControl(''),
        });

        // Since this field series/set has an image to be updated, also
        // add a file object to store along with the form
        this.productBaseForms[product.docId].images[0] = {
          destroyImage$: new Subject(),
          file: undefined,
          uploadPercent: 0,
          isUploading: false,
          previewUrl: '',
        };

        // Generate image objects and a matching form for each color that the product has
        this.productColorForms[product.docId].form = this.formBuilder.group({});
        product.colorLinks.forEach((colorLink, i) => {
          this.productColorForms[product.docId].images[i] = {
            destroyImage$: new Subject(),
            file: undefined,
            uploadPercent: 0,
            isUploading: false,
            previewUrl: '',
          };

          // Dynamically build a reactive form structure for each product's colors
          //  Runs a second loop within the loop that we're currently in to add each existing color to the form
          this.productColorForms[product.docId].form.addControl(
            `colorLink_${i}`,
            new FormControl(null, [
              Validators.pattern(/^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g),
              Validators.required,
            ])
          );
        });

        // Generate image objects and a matching form for each image that the product has
        this.productImageForms[product.docId].form = this.formBuilder.group({});
        product.imageLinks.forEach((imageLink, i) => {
          this.productImageForms[product.docId].images[i] = {
            destroyImage$: new Subject(),
            file: undefined,
            uploadPercent: 0,
            isUploading: false,
            previewUrl: '',
          };

          // Dynamically build a reactive form structure for each product's images
          //  Runs a second loop within the loop that we're currently in to add each existing image to the form
          this.productImageForms[product.docId].form.addControl(
            `imageLink_${i}`,
            new FormControl(null, [
              Validators.pattern(/^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g),
              Validators.required,
            ])
          );
        });

        // Generate an image object to use when adding a new image
        this.productNewColorForms[product.docId].images[0] = {
          destroyImage$: new Subject(),
          file: undefined,
          uploadPercent: 0,
          isUploading: false,
          previewUrl: '',
        };

        // Build the form that's for adding an additional color field to a product and
        //  store it in the DynamicForms object for this series of fields
        this.productNewColorForms[product.docId].form = this.formBuilder.group({
          newColorLink: new FormControl(null, [
            Validators.pattern(/^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g),
          ]),
        });

        // Generate an image object to use when adding a new image
        this.productNewImageForms[product.docId].images[0] = {
          destroyImage$: new Subject(),
          file: undefined,
          uploadPercent: 0,
          isUploading: false,
          previewUrl: '',
        };

        // Build the form that's for adding an additional color field to a product and
        //  store it in the DynamicForms object for this series of fields
        this.productNewImageForms[product.docId].form = this.formBuilder.group({
          newImageLink: new FormControl(null, [
            Validators.pattern(/^.*\.(SVG|svg|PNG|png|JPG|jpg|JPEG|jpeg)$/g),
          ]),
        });
      });
    });
  }

  // Removes a product from a list.
  removeProduct(product) {
    // Dev Content, remove when converted
    // this.loadedProducts.splice(this.loadedProducts.indexOf(product), 1);
  }

  // Dev Content, remove when converted
  // Generates an empty product.
  addProduct() {
    // this.loadedProducts.push({
    //   title: '',
    //   order: 1,
    //   catId: 'zyjpq73OtC95AG9GP7fU',
    //   details: [],
    //   imageLinks: [],
    //   primaryImageLink: '',
    //   colorLinks: [],
    //   prices: [],
    //   docId: '3',
    //   note: '',
    // });
  }

}
