// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hostUrl: 'https://stagingopps.quickmobilerepair.com/',
  mapsKey: 'AIzaSyAnNF9kcx0B1kP6oV30yZ6QMgr62_aXM7k',
  firebase: {
    apiKey: 'AIzaSyBlHjHBdqKkjoOBBC_kP2qpT-o_gJVihn8',
    authDomain: 'ngrp-dev.firebaseapp.com',
    projectId: 'ngrp-dev',
    storageBucket: 'ngrp-dev.appspot.com',
    messagingSenderId: '135956054286',
    appId: '1:135956054286:web:83be138de158e24aa3d75f',
    measurementId: 'G-VK2SJX3P08',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
