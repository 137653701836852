import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocationDoc} from '../models/location.model';
import * as moment from 'moment-timezone';
import {WindowService} from '../services/window/window.service';
import {MapService} from '../map.service';

@Component({
  selector: 'app-store-card',
  templateUrl: './store-card.component.html',
  styleUrls: ['./store-card.component.scss'],
})
export class StoreCardComponent implements OnInit {

  @Input() loc: LocationDoc;
  @Output() storeChosen = new EventEmitter();
  isOpen = false;
  constructor(public win: WindowService, public map: MapService) { }

  ngOnInit() {
    this.setOpen();
  }

  setOpen(){
    const zone = this.loc.timeZone;
    const current = moment().utcOffset(zone, false);
    let open;
    let close;
    if (current.day() === 0 && this.loc.sundayHours.isOpen){
      open = moment(this.loc.sundayHours.openTime, 'hh:mm A').utcOffset(zone, true);
      close = moment(this.loc.sundayHours.closeTime, 'hh:mm A').utcOffset(zone, true);
    } else if (current.day() === 6 && this.loc.saturdayHours.isOpen){
      open = moment(this.loc.saturdayHours.openTime, 'hh:mm A').utcOffset(zone, true);
      close = moment(this.loc.saturdayHours.closeTime, 'hh:mm A').utcOffset(zone, true);
    } else {
      open = moment(this.loc.weekdayHours.openTime, 'hh:mm A').utcOffset(zone, true);
      close = moment(this.loc.weekdayHours.closeTime, 'hh:mm A').utcOffset(zone, true);
    }

    this.isOpen = current.isAfter(open) && current.isBefore(close);
  }


  selectStore(location: LocationDoc) {
    this.storeChosen.next(location);
  }

  phoneFormat(input) {
    if(!input || isNaN(input)) {return `input must be a number was sent ${input}`;}
    if(typeof(input) !== 'string') {input = input.toString();}
    if(input.length === 10){
      return input.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
    } else if(input.length < 10) {
      return 'was not supplied enough numbers please pass a 10 digit number';
    } else if(input.length > 10) {
      return 'was supplied too many numbers please pass a 10 digit number';
    }else{
      return 'something went wrong';
    }
  }

}
