import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Keyword, MetaTags} from '../../models/keyword';
import {NavigationEnd, Router} from '@angular/router';
import * as _ from 'lodash';
import {Product} from '../../models/catalog.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
/**
 * Added By Siddharth Saxena -
 * This service is added to handle all the tasks related to meta tags for the pages
 * It's loadKeywords function is must to run before any use of this service
 */
export class MetaService {

  tags: MetaTags[] = [];
  exclusions: string[] = [];
  private readonly baseUrl = environment.hostUrl;
  constructor(private meta: Meta, private title: Title,
              private http: HttpClient, private route: Router) {
  }

  /**
   * This method will load the json file containing all meta tags for all pages
   * and must be run once in order to use this service
   */
  loadKeywords(){
    this.http.get<Keyword>('../assets/json/meta.json').subscribe(data => {
      this.tags = data.tags;
      this.exclusions = data.exclusions;
      this.route.events.subscribe(change => {
          this.checkForChanges(change);
      });
      this.checkForChanges();
    }, error => {
      console.log('Failed to load keywords!', error);
    });
  }

  /**
   * This method will be called to check for changes in url and it will further make calls
   * to add tags in the
   */
  checkForChanges(change?){
    if (!change || change instanceof NavigationEnd){
      const isExists = _.find(this.tags, {route: this.route.url});
      if (isExists) {
        this.addTag(this.route.url);
      } else {
        // console.log('Appeared to be excluded!');
      }
    }
  }

  /**
   * This function will add tag if applicable on the route,
   * except the excluded routes, such as Product Details Page
   *
   * @param route : string  - is current route e.g /home
   */
  addTag(route: string){
    const tag = _.find(this.tags, {route});
    if (tag){
      this.title.setTitle(tag.title);
      this.meta.addTags([
        {name: 'description', content: tag.description},
        {name: 'robots', content: 'index, follow'},
        { property: 'og:title', content: tag.title},
        { property: 'og:url', content: this.baseUrl + tag.url},
      ]);
      if (tag.keywords){
        this.meta.addTag({name: 'keywords', content: tag.keywords});
      }
      if (tag.image){
        this.meta.addTag({ property: 'og:image', content: tag.image});
      }
    }
  }

  /**
   * This function is supposed to insert meta tags for products
   *
   * @param product : Product object to be passed here
   */
  addProductTag(product: Product){
    this.title.setTitle(product.title);
    this.meta.addTags([
      {name: 'keywords', content: product.title},
      {name: 'description', content: product.title},
      {name: 'robots', content: 'index, follow'},
      { property: 'og:title', content: product.title},
      { property: 'og:url', content: this.baseUrl + this.route.url},
      { property: 'og:image', content: product.primaryImageLink}
    ]);
  }


}
