import {Injectable} from '@angular/core';
import {LocationDoc} from './models/location.model';
import LatLng = google.maps.LatLng;
import * as _ from 'lodash';
import {Geolocation} from '@capacitor/geolocation';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor() { }

  /**
   * Find distance between two points on earth
   *
   * @param lat1 - Origin Latitude
   * @param lon1 - Origin Longitude
   * @param lat2 - Destination Latitude
   * @param lon2 - Destination Longitude
   * @param unit - metric or imperial
   */
  getDistanceFromLatLon(lat1,lon1,lat2,lon2, unit: string = 'metric') {
    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat2 - lat1) * p)/2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p))/2;

    let d = 12742 * Math.asin(Math.sqrt(a));
    if (unit === 'imperial'){
      d = d * 0.62137;
    }
    return d;
  }

  /**
   * Add distance to location docs
   *
   * @param locations
   * @param lat
   * @param lng
   */
  addDistanceToLoc(locations, lat, lng): LocationDoc[] {
    for (const loc of locations){
      loc.distance = this.getDistanceFromLatLon(loc.geolocation.latitude, loc.geolocation.longitude, lat, lng);
      loc.distance = +loc.distance > 100 ? Math.round(+loc.distance) : (+loc.distance).toFixed(2);
    }
    locations = _.orderBy(locations, ['distance'], ['ASC']);
    return locations;
  }

  /**
   * Get on road distance (driving distance using Google Maps api)
   *
   * @param origin
   * @param destination
   */
  getDistance(origin: LatLng, destination: LatLng){
    const matrix = new google.maps.DistanceMatrixService();
    return new Promise(async (resolve, reject) => {
      const response = await matrix.getDistanceMatrix({
        origins: [origin],
        unitSystem: google.maps.UnitSystem.METRIC,
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
      });
      if (response.rows[0].elements[0].status === 'OK'){
        const txt = response.rows[0].elements[0].distance.text.replace('mi', 'miles away');
        resolve(txt);
      } else {
        reject('Distance is not available between these points.');
      }
    });
  }

   async getLocation() {
    return new Promise(async (resolve, reject) => {
      if(navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(async () => {
           console.log('All good@!');
           resolve(true);
         },
         () => {
           console.log('All Bad@!');
           resolve(false);
         });
     } else {
        console.log('All Bad@!');
        resolve(false);
     }
    });
  }

  positionError() {
    console.log('Geolocation is not enabled. Please enable to use this feature');
    this.getLocation();
  }

  showPosition(){
    console.log('posititon accepted');
  }

  phoneFormat(input) {
    if(!input || isNaN(input)) {return `input must be a number was sent ${input}`;}
    if(typeof(input) !== 'string') {input = input.toString();}
    if(input.length === 10){
      return input.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
    } else if(input.length < 10) {
      return 'was not supplied enough numbers please pass a 10 digit number';
    } else if(input.length > 10) {
      return 'was supplied too many numbers please pass a 10 digit number';
    }else{
      return 'something went wrong';
    }
  }

  setOpen(loc: LocationDoc): boolean {
    const zone = loc.timeZone;
    const current = moment().utcOffset(zone, false);
    let open;
    let close;
    if (current.day() === 0 && loc.sundayHours.isOpen){
      open = moment(loc.sundayHours.openTime, 'hh:mm A').utcOffset(zone, true);
      close = moment(loc.sundayHours.closeTime, 'hh:mm A').utcOffset(zone, true);
    } else if (current.day() === 6 && loc.saturdayHours.isOpen){
      open = moment(loc.saturdayHours.openTime, 'hh:mm A').utcOffset(zone, true);
      close = moment(loc.saturdayHours.closeTime, 'hh:mm A').utcOffset(zone, true);
    } else {
      open = moment(loc.weekdayHours.openTime, 'hh:mm A').utcOffset(zone, true);
      close = moment(loc.weekdayHours.closeTime, 'hh:mm A').utcOffset(zone, true);
    }


    return current.isAfter(open) && current.isBefore(close);
  }


}
