import { LocationDoc } from './location.model';
import { Cart } from './cart.model';
export interface User {
  displayName?: string;
  email: string;
  uid: string;
  userType?: any;
  phone?: number;
  myStore?: any;
  orders?: Array<string>;
  cart?: any;
  emailVerified: boolean;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: number;
}

export enum userTypes {
  customer = 'customer',
  admin = 'admin',
  superAdmin = 'superAdmin',
}

export interface adminBuilder {
  userData: {
    email: string;
    userType: userTypes;
    displayName: string;
  };
  password: string;
}
