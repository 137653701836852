// Core+
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

// Models
import {Cart} from '../../models/cart.model';
import {AuthService} from '../auth/auth.service';
import {User} from '../../models/user.model';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AlertController} from '@ionic/angular';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CartService {

  public cart$: BehaviorSubject<Cart> = new BehaviorSubject<Cart>(null);
  public cartLength = 0;
  store: string;
  private cart: Cart;
  private user: User;
  private migrationPending = false;
  private isBrowser = false;

  constructor(private afs: AngularFirestore, private alertController: AlertController,
              private auth: AuthService, private functions: AngularFireFunctions,
              private router: Router, @Inject(PLATFORM_ID) private platformId: object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser){
      this.init();
    }
  }

  async init() {
    this.auth.user$.subscribe(async user => {
      this.user = user;
    });
    try {
      this.cart = await this.getCart();
    } catch (e) {
      console.log('Unable to get cart!');
    }
    await this.listenForCartUpdates();
    this.cart$.subscribe(cart => {
      if (cart) {
        this.cart = cart;
        this.cartLength = cart.items.length;
      }
    });
  }

  cartDoc$(docId): Observable<Cart> {
    // console.log(docId.subscribe(docId));
    return this.afs
      .collection<Cart>('carts')
      .doc(docId)
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((change) => {
          // // This will return an observable of an Array of categories
          // const carts = changes
          //   // Filter out any products which don't belong to the caller category
          //   // .filter((change) => change.payload.doc.id === docId)
          //   .map((change) => {
          //     const cart: Cart = {
          //       ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
          //     };
          //     return cart;
          //   });
          // return carts;
          const cart: Cart = {
            ...change.payload.data(), // Adds properties to the object for any properties of the data object
          };
          this.cartLength = cart.items.length;
          return cart;
        })
      );
  }

  markForMigration() {
    this.migrationPending = true;
  }

  /**
   * Add item to cart, this function must be used to add items in cart
   *
   * @param dataPayload - Data what needed to be added in cart
   */
  public async addToCart(dataPayload): Promise<unknown> {
    if (this.isBrowser) {
      return new Promise(async (resolve, reject) => {
        // If user is logged in then let him continue to add data to db
        if (await this.auth.isLoggedIn()) {
          // Submit the payload to firestore
          this.auth.user$.pipe(take(1)).subscribe(async (user: User) => {
            // Create a references to the user's cart doc for various purposes
            if (user) {
              if (user.userType !== 'customer') {
                // inform only customer can do this operation
                const alert = await this.alertController.create({
                  header: 'Incorrect User Type',
                  message: 'Only a customer can add items to cart!',
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: 'Go To Storefront',
                      handler: (data) => {
                        this.router.navigateByUrl('/catalog/home');
                      },
                    },
                  ],
                });
                await alert.present();
                return;
              }

              const callable = this.functions.httpsCallable('addToCart');
              callable({product: dataPayload, guest: false})
                .subscribe(res => {
                  const {cart} = res;
                  this.cart$.next(cart);
                  resolve(cart);
                });
            }

          });
        } else {
          // check if you have any info about cart in localstorage
          const cartStr = localStorage.getItem('cart');
          const expiry = +(localStorage.getItem('cartExpiry'));
          const startDate = new Date();
          let cartId = '';
          if (cartStr && cartStr !== 'undefined') {
            console.log(cartStr);
            const cart = JSON.parse(cartStr);
            if (cart && (startDate.getTime() < expiry) && cartStr) {
              cartId = cart.docId;
            }
          }

          const callable = this.functions.httpsCallable('addToCart');
          callable({product: dataPayload, guest: true, cartId})
            .subscribe(res => {
              const {cart} = res;
              if (cart){
                this.cart$.next(cart);
                const exp = new Date().getTime() + 14400000;
                localStorage.setItem('cart', JSON.stringify(cart));
                localStorage.setItem('cartExpiry', String(exp));
                this.cartLength = cart.items.length;
              }

              resolve(cart);
            });
        }

      });
    }
  }

  /**
   * Update item to cart, this function must be used to update items in cart
   *
   * @param dataPayload - Data what needed to be updated in cart
   * @param index: Number - Product ref which needs to be updated in cart
   */
  public async updateCart(dataPayload, index: number): Promise<unknown> {
    return new Promise(async (resolve, reject) => {
      const callable = this.functions.httpsCallable('updateCart');
      callable({product: dataPayload, cartId: this.cart.docId, index})
        .subscribe(res => {
          const {status} = res;
          const {cart} = res;
          if (status === 0) {
            this.cart$.next(cart);
            resolve(cart);
          } else {
            reject(false);
          }
        }, error => {
          reject(false);
        });
    });
  }

  /**
   * Get Cart object, this function returns user's cart object server and lcoal both
   */
  public getCart(): Promise<Cart> {
    return new Promise(async (resolve, reject) => {
      if (await this.auth.isLoggedIn()) {
        this.auth.user$.pipe(take(1)).subscribe((user: User) => {
          // Create a references to the user's cart doc for various purposes
          const callable = this.functions.httpsCallable('getCart');
          callable({cartId: user.cart.docId})
            .subscribe(res => {
              const {status} = res;
              const {cart} = res;
              this.store = cart.store;
              if (status === 0) {
                this.cart$.next(cart);
                resolve(cart);
              } else {
                reject(false);
              }
            });
        });
      } else {
        if (this.isBrowser){
          this.store = localStorage.getItem('store');
          if (this.cart) {
            resolve(this.cart);
          } else {
            if (this.isBrowser) {
              const mCart = this.checkLocalCart();
              if (mCart) {
                const callable = this.functions.httpsCallable('getCart');
                callable({cartId: mCart.docId})
                  .subscribe(res => {
                    const {status} = res;
                    const {cart} = res;
                    // console.log('New Products!', products);
                    if (status === 0) {
                      this.cart$.next(cart);
                      resolve(cart);
                    } else {
                      reject(false);
                    }
                  });
              } else {
                this.cart$.next(null);
                localStorage.setItem('cart', '{}');
                localStorage.setItem('cartExpiry', '0');
                this.cartLength = 0;
                resolve(null);
              }
            } else {
              resolve(null);
            }
          }
        }
      }
    });
  }

  /**
   * Migrate local cart to server, this function migrate cart to DB from localstorage
   */
  public migrateCart(): Promise<Cart> {
    if (this.isBrowser) {
      return new Promise(async (resolve, reject) => {
        const migrate = localStorage.getItem('migrate') === 'true';
        if (migrate) {
          this.auth.user$.pipe(take(1)).subscribe(async (user: User) => {
            // Create a references to the user's cart doc for various purposes
            if (user) {
              if (user.userType !== 'customer') {
                // inform only customer can do this operation
                const alert = await this.alertController.create({
                  header: 'Incorrect User Type',
                  message: 'Only a customer can add items to cart!',
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: 'Go To Storefront',
                      handler: (data) => {
                        this.router.navigateByUrl('/catalog/home');
                      },
                    },
                  ],
                });
                await alert.present();
                return;
              }
              const cartDoc$ = this.cartDoc$(user.cart.docId);
              cartDoc$.pipe(take(1)).subscribe(async (cartDoc: Cart) => {
                const cart = await this.evaluateTotal(cartDoc, user);
                if (cartDoc.items.length > 0) {
                  const localCart = await this.evaluateTotal(this.getLocalCart());
                  for (const item of localCart.items) {
                    if (!cartDoc.itemIds.includes(item.docId)) {
                      cartDoc.items.push(item);
                    }
                  }
                  const mCart = await this.evaluateTotal(cartDoc, user);
                  mCart.store = this.store;
                  await this.afs.collection('carts').doc(mCart.docId).set(mCart);
                  localStorage.setItem('migrate', 'false');
                  this.clearLocalCart();
                  this.cart$.next(mCart);
                  this.migrationPending = false;
                  resolve(cart);
                }
              });
            } else {
              const localCart = this.getLocalCart();
              const callable = this.functions.httpsCallable('migrateCart');
              callable({items: localCart.items, store: this.store})
                .subscribe(cart => {
                  localStorage.setItem('migrate', 'false');
                  localStorage.setItem('cartId', cart.docId);
                  this.cart$.next(cart);
                  this.migrationPending = false;
                  resolve(cart);
                });
            }
          });
        }
      });
    }
  }

  /**
   * Remove cart item, this function can remove cart item regardless of online or offline cart
   *
   * @param index - Index of item in item array
   */
  public removeItem(index: number): Promise<Cart> {
    return new Promise(async (resolve, reject) => {
      const cartId = this.cart.docId;
      if (cartId) {
        const callable = this.functions.httpsCallable('removeFromCart');
        callable({index, cartId})
          .subscribe(res => {
            const {cart} = res;
            this.cart$.next(cart);
            resolve(cart);
          });
      } else {
        console.log('Cart appears to be blank!');
      }

    });
  }

  public async addStoreLocation(storeId: string): Promise<Cart> {
    localStorage.setItem('store', storeId);
    return new Promise(async (resolve, reject) => {
      const cartId = this.cart ? this.cart.docId : '';
      const callable = this.functions.httpsCallable('addStoreLocation');
      callable({storeId, cartId})
        .subscribe(res => {
          const {cart} = res;
          this.store = cart.store;
          this.cart$.next(cart);
          resolve(cart);
        });
    });
  }


  /**
   * This function can clear local cart, it is useful when user login
   * or a guest checkout is completed
   */
  public clearLocalCart() {
    localStorage.setItem('cart', null);
    this.loadCart();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  checkLocalCart() {
    if (this.isBrowser) {
      const cartStr = localStorage.getItem('cart');
      const expiry = +(localStorage.getItem('cartExpiry'));
      const startDate = new Date();
      const mCart = (cartStr && cartStr !== 'undefined') ? JSON.parse(cartStr) : undefined;
      if (mCart && (startDate.getTime() < expiry) && cartStr) {
        return mCart;
      } else {
        return false;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async setStoreInfo(store: string) {
    this.store = store;
    this.cart.store = store;
    if (await this.auth.isLoggedIn()) {
      await this.afs
        .collection<Cart>('carts')
        .doc(this.cart.docId)
        .update({store});
    } else {
      localStorage.setItem('store', store);
    }
  }

  /**
   * Calculate cart total and return cart object
   *
   * @param cart - Cart object
   * @param user? - user object, can be null or blank
   * @private
   */
  private async evaluateTotal(cart: Cart, user?: User) {
    // Loop through each item in the cart to build an up-to-date subTotal
    let subTotal = 0;
    if (cart.items) {
      cart.items.forEach((item) => {
        subTotal = subTotal + (item.count * item.price);
      });
    }
    if (cart.items && cart.items.length === 0) {
      subTotal = 0;
    }

    let taxRate = 0.08;
    if (await this.auth.isLoggedIn() && user) {
      taxRate = (user.myStore.taxRate ? user.myStore.taxRate : 0.08);
    }
    // Get an up-to-date tax amount and final total using the user's current store and the new subTotal
    const taxTotal = subTotal * taxRate;
    const total = subTotal + taxTotal;
    cart.subTotal = subTotal;
    cart.total = total;
    cart.tax = taxTotal;
    return cart;
  }

  private async loadCart() {
    let cart = this.getLocalCart();
    cart = await this.evaluateTotal(cart);
    this.cart$.next(cart);
    this.cartLength = cart.items ? cart.items.length : 0;
  }

  /**
   * Get cart object from localstorage or make new if doesn't exists
   *
   * @private
   */
  private getLocalCart() {
    if (this.isBrowser) {
      let cart = JSON.parse(localStorage.getItem('cart'));
      if (!cart) {
        cart = {
          items: [],
          tax: 0,
          total: 0,
          subTotal: 0,
          itemIds: [],
          guest: true
        };

        localStorage.setItem('cart', JSON.stringify(cart));
      }
      return cart;
    }
  }

  private async listenForCartUpdates() {
    this.auth.user$.subscribe(async (user: User) => {
      // Create a references to the user's cart doc for various purposes
      const cart = await this.getCart();
      this.cart$.next(cart);
    });
  }

}
