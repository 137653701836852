<ion-content *ngIf="win.isBrowser" class="ion-padding">
  <ion-grid *ngIf="!win.isMobile && !win.isTablet" class="ion-no-margin">
    <ion-row>
      <ion-col size="auto">
        <div class="location-wrapper">
          <app-store-card (storeChosen)="selectStore(loc)" *ngFor="let loc of allLocations"
                          [loc]="loc"></app-store-card>
        </div>
      </ion-col>
      <ion-col>
        <ion-grid class="ion-no-padding">
          <ion-row class="ion-no-padding">
            <ion-col class="ion-no-padding">
              <ion-card class="search-card">
                <ion-grid>
                  <ion-row>
                    <ion-col size="auto">
                      <ion-icon class="icon" name="search"></ion-icon>
                    </ion-col>
                    <ion-col>
                      <input (onAddressChange)="handleAddressChange($event)" class="search-box"
                             ngx-google-places-autocomplete placeholder="Search Location">
                    </ion-col>
                    <ion-col size="auto">
                      <ion-icon (click)="myLocation()" class="loc-icon" name="locate"></ion-icon>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card>
            </ion-col>
            <ion-col class="ion-no-padding" size="auto">
              <ion-icon (click)="close()" class="close-icon" name="close-outline"></ion-icon>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <google-map #map
                          *ngIf="ready"
                          [center]="options.center"
                          [zoom]="options.zoom"
                          [options]="options" height="70vh" width="100%">
                <map-marker #marker="mapMarker" (mapClick)="openInfoWindow(marker, markerPosition)"
                            *ngFor="let markerPosition of markerPositions"
                            [icon]="'assets/icons/location-pin.svg'"
                            [options]="markerOptions"
                            [position]="markerPosition"></map-marker>
                <map-info-window>
                  <div *ngIf="selectedLocation">
                    <ion-grid class="ion-no-padding location-item">
                      <ion-row>
                        <ion-col class="ion-padding">
                          <span class="info-title">{{selectedLocation.shortName}}</span>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="auto">
                          <ion-icon class="icon-primary" name="location"></ion-icon>
                        </ion-col>
                        <ion-col class="ion-padding-start">
                          <a [href]="selectedLocation.mapsUrl" target="_blank"> {{selectedLocation.streetAddress}}
                            , {{selectedLocation.suite}}, <br
                              *ngIf="!win.isMobile && !win.isTablet">{{selectedLocation.state}}
                            , {{selectedLocation.zipCode}}</a>
                        </ion-col>
                      </ion-row>
                    </ion-grid>

                    <ion-grid class="ion-no-padding location-item">
                      <ion-row>
                        <ion-col size="auto">
                          <ion-icon class="icon-primary" name="call-outline"></ion-icon>
                        </ion-col>
                        <ion-col class="ion-padding-start">
                          <a
                            href="tel:{{selectedLocation.phone}}">{{mapService.phoneFormat(selectedLocation.phone)}}</a>
                        </ion-col>
                      </ion-row>
                    </ion-grid>

                    <ion-grid class="ion-no-padding location-item">
                      <ion-row class="ion-no-padding">
                        <ion-col size="auto">
                          <ion-icon class="icon-primary" name="time-outline"></ion-icon>
                        </ion-col>
                        <ion-col class="ion-padding-start">{{selectedLocation.weekdayHours.openTime}}
                          - {{selectedLocation.weekdayHours.closeTime}}
                          <span [ngClass]="isOpen ? '' : 'color-red'">({{isOpen ? 'Open' : 'Closed'}})</span>
                        </ion-col>
                      </ion-row>
                    </ion-grid>

                    <ion-grid class="ion-no-padding location-item">
                      <ion-row class="ion-no-padding">
                        <ion-col size="auto">
                          <ion-icon class="icon-primary" name="shuffle"></ion-icon>
                        </ion-col>
                        <ion-col class="ion-padding-start">{{selectedLocation.distance}} Miles Away</ion-col>
                      </ion-row>
                    </ion-grid>

                    <div (click)="selectStore(selectedLocation)" class="select-btn" color="primary">Select Store</div>
                  </div>
                </map-info-window>
              </google-map>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div *ngIf="win.isMobile || win.isTablet" class="map-padding">
    <ion-grid>
      <ion-row class="ion-no-padding">
        <ion-col class="ion-no-padding">
          <ion-card class="search-card">
            <ion-icon class="icon" name="search"></ion-icon>
            <input (onAddressChange)="handleAddressChange($event)" class="search-box"
                   ngx-google-places-autocomplete placeholder="Search Location">
          </ion-card>
        </ion-col>
        <ion-col class="ion-no-padding" size="auto">
          <ion-icon (click)="close()" class="close-icon" name="close-outline"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
    <google-map #map
                *ngIf="ready"
                [center]="options.center"
                [zoom]="options.zoom"
                [options]="options" height="90vh" width="100%">
      <map-marker #marker="mapMarker" (mapClick)="openInfoWindow(marker, markerPosition)"
                  *ngFor="let markerPosition of markerPositions"
                  [icon]="'assets/icons/location-pin.svg'"
                  [options]="markerOptions"
                  [position]="markerPosition"></map-marker>
      <map-info-window>
        <div *ngIf="selectedLocation">
          <ion-grid class="ion-no-padding location-item">
            <ion-row>
              <ion-col class="ion-padding">
                <span class="info-title">{{selectedLocation.shortName}}</span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="auto">
                <ion-icon class="icon-primary" name="location"></ion-icon>
              </ion-col>
              <ion-col class="ion-padding-start">
                <a [href]="selectedLocation.mapsUrl" target="_blank"> {{selectedLocation.streetAddress}}
                  , {{selectedLocation.suite}}, <br *ngIf="!win.isMobile && !win.isTablet">{{selectedLocation.state}}
                  , {{selectedLocation.zipCode}}</a>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-grid class="ion-no-padding location-item">
            <ion-row>
              <ion-col size="auto">
                <ion-icon class="icon-primary" name="call-outline"></ion-icon>
              </ion-col>
              <ion-col class="ion-padding-start">
                <a href="tel:{{selectedLocation.phone}}">{{mapService.phoneFormat(selectedLocation.phone)}}</a>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-grid class="ion-no-padding location-item">
            <ion-row class="ion-no-padding">
              <ion-col size="auto">
                <ion-icon class="icon-primary" name="time-outline"></ion-icon>
              </ion-col>
              <ion-col class="ion-padding-start">{{selectedLocation.weekdayHours.openTime}}
                - {{selectedLocation.weekdayHours.closeTime}}
                <span [ngClass]="isOpen ? '' : 'color-red'">({{isOpen ? 'Open' : 'Closed'}})</span>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-grid class="ion-no-padding location-item">
            <ion-row class="ion-no-padding">
              <ion-col size="auto">
                <ion-icon class="icon-primary" name="shuffle"></ion-icon>
              </ion-col>
              <ion-col class="ion-padding-start">{{selectedLocation.distance}} Miles Away</ion-col>
            </ion-row>
          </ion-grid>

          <div (click)="selectStore(selectedLocation)" class="select-btn" color="primary">Select Store</div>
        </div>
      </map-info-window>
    </google-map>
    <div class="mobile-location-wrapper">
      <app-store-card (storeChosen)="selectStore(loc)" *ngFor="let loc of allLocations"
                      [loc]="loc" class="loc-card">

      </app-store-card>
    </div>
  </div>
</ion-content>
