// Core+
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { AuthService } from '../services/auth/auth.service';

// Models
import { User, userTypes } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.authService.user$.subscribe((user: User) => {
      if (!user) {
        // window.alert('Access denied, you are not logged in! - UA-0002');
        this.router.navigate(['catalog/home']);
        return;
      }
      if (user.userType === userTypes.admin) {
        return;
      }
      if (user.userType === userTypes.superAdmin) {
        return;
      }

      // window.alert('Access denied, you are not an admin/superAdmin! - UA-0003');
      this.router.navigate(['profile/dashboard']);
      return;
    });
    return true;
  }
}
