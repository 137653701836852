// Core+
import { Component, OnInit } from '@angular/core';

// Ionic
import { LoadingController, ModalController } from '@ionic/angular';

// Angular forms
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

// Services
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-add-admin-user',
  templateUrl: './add-admin-user.page.html',
  styleUrls: ['./add-admin-user.page.scss'],
})
export class AddAdminUserPage implements OnInit {
  addAdminForm: FormGroup;

  constructor(
    public viewCtrl: ModalController,
    private formBuilder: FormBuilder,
    public loadingController: LoadingController,
    private authService: AuthService
  ) {}

  // Called when the drop down selection changes
  updateOpenStatus(e) {
    // Set our userType form control to the newly selected value
    this.addAdminForm.controls.userType.setValue(e.detail.value);
  }

  async submitForm() {
    // Turn on the loading indicator
    const loading = await this.loadingController.create({
      backdropDismiss: false,
    });
    await loading.present();

    // Make sure the form is valid
    if (this.addAdminForm.valid) {
      this.authService
        // Submit the new user data to the server
        .registerAdminUser(
          this.addAdminForm.controls.email.value,
          this.addAdminForm.controls.password.value,
          this.addAdminForm.controls.displayName.value,
          this.addAdminForm.controls.userType.value
        )
        .then((res$) =>
          res$.subscribe(async (res) => {
            await loading.dismiss(res);
            return this.close();
          })
        );
    }
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  ngOnInit() {
    this.addAdminForm = this.formBuilder.group({
      displayName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      userType: new FormControl('', [Validators.required]),
    });
  }
}
