// Core+
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  AlertController,
  ModalController,
  NavController,
  NavParams,
  PopoverController,
} from '@ionic/angular';
import {take} from 'rxjs/operators';

// Firestore
// Services
import {AuthService} from 'src/app/services/auth/auth.service';
import {CartService} from 'src/app/services/cart/cart.service';

// Model
import {Product} from 'src/app/models/catalog.model';
import {User} from 'src/app/models/user.model';
import {ProductRef} from 'src/app/models/cart.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cart-config',
  templateUrl: './cart-config.page.html',
  styleUrls: ['./cart-config.page.scss'],
})
export class CartConfigPage implements OnInit, AfterViewInit {
  @Input() productDoc: Product;
  @Input() productRef: ProductRef;
  @Input() edit = false;
  @Input() index = -1;
  @Input() passThroughData: any;
  loading = false;
  adding = false;
  productConfigForm: FormGroup;


  // screenSize = window.innerWidth <= 640 ? 'mobile' : 'desktop';

  constructor(
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public alertController: AlertController,
    public navController: NavController,
    private cartService: CartService,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    public router: Router
  ) {
    // this.productDoc = navParams.get('doc');
    //this.editMode = navParams.get('edit');
    this.passThroughData = navParams.get('passThroughData');
  }

  // Closes the modal
  close() {
    this.viewCtrl.dismiss();
  }

  async submitForm() {
    if (this.productConfigForm.valid) {
      // Use the index passed from the form to pull the data for the option the user selected
      this.adding = true;
      const priceTier =
        this.productDoc.prices[this.productConfigForm.controls.priceTier.value];
      const color =
        this.productDoc.colorLinks[this.productConfigForm.controls.color.value];
      const count = this.productConfigForm.controls.count.value;

      const carrier = this.productConfigForm.controls.carrier.value
        ? this.productDoc.note
        : false;

      let carrierPrice;
      let carrierTag;

      if (carrier) {
        carrierPrice = parseFloat(carrier.split('$')[1].split(' ')[0]);
        carrierTag = carrier;
      }

      // Build a data payload with all of the info that will be added to the cart
      const dataPayload: ProductRef = {
        price: parseFloat(priceTier.price),
        title: this.productDoc.title,
        docId: this.productDoc.docId,
        count: 1,
        imageUrl: this.productDoc.primaryImageLink,
      };

      if (carrierTag) {
        dataPayload.carrierTag = carrierTag;
        console.log(dataPayload.price, dataPayload.carrierTag);
      }

      if (carrierPrice) {
        dataPayload.price = +priceTier.price + +carrierPrice;
      }

      // Adds the color if it's present
      if (color) {
        dataPayload.color = color;
      }
      // Adds the price note/detail if it's present
      if (priceTier.note) {
        dataPayload.priceNote = priceTier.note;
      }

      if (count) {
        dataPayload.count = count;
      }

      try {
        if (this.edit) {
          await this.cartService.updateCart(dataPayload, this.index);

        } else {
          await this.cartService.addToCart(dataPayload);
        }
      } catch (e) {
        console.log('Unable to add to cart!');
      }
      this.close();
    }

    if (!this.edit) {
      // Open alert to move to cart.
      const alert = await this.alertController.create({
        cssClass: 'go-to-cart-popup',
        header: 'Proceed to Checkout?',
        buttons: [
          {
            text: 'Continue Shopping',
            handler: () => {
              this.navController.back();
            },
          },
          {
            text: 'Proceed to Checkout',
            handler: () => {
              this.router.navigateByUrl('/cart');
            },
          },
        ],
      });
      await alert.present();
    }

    this.adding = false;
  }

  ngOnInit() {
    let presetColor: any = false;
    let carrierTag = false;
    let presetPricenote: any = false;
    let count = 1;

    // if (this.passThroughData) {
    //   // Determine the data type.
    //   if (this.passThroughData.price) {
    //     presetPricenote = 0;
    //   } else if (this.passThroughData.img) {
    //     presetColor = this.productDoc.colorLinks.indexOf(
    //       this.passThroughData.img
    //     );
    //   }
    // }

    if (this.edit) {
      // Determine the data type.
      if (this.productRef.price) {
        if (this.productDoc.prices.length === 1) {
          presetPricenote = 0;
        } else {
          for (let i = 0; i < this.productDoc.prices.length; i++) {
            if (+this.productDoc.prices[i].price === this.productRef.price ||
              this.productDoc.prices[i].note === this.productRef.priceNote) {
              presetPricenote = i;
              break;
            }
          }
        }
      }

      if (this.productRef.color) {
        presetColor = this.productDoc.colorLinks.indexOf(
          this.productRef.color
        );
      }

      if (this.productRef.carrierTag) {
        carrierTag = true;
      }

      if (this.productRef.count) {
        count = this.productRef.count;
      }
    }
    // this.productDoc = navParams.get('doc');

    console.log(this.productDoc, this.navParams.get('productDoc'));

    this.productConfigForm = this.formBuilder.group({
      priceTier: new FormControl(presetPricenote !== false ? presetPricenote : '', [Validators.required]
      ), // Makes this field required in order for the .valid flag to get set to true

      color: new FormControl(
        presetColor !== false ? presetColor : '',
        this.productDoc.colorLinks.length >= 1 && [Validators.required]
      ),
      count: new FormControl(count, [
        Validators.required,
        Validators.min(1),
        Validators.max(10),
      ]),
      carrier: new FormControl(carrierTag),
    });

    if (this.productDoc.colorLinks && this.productDoc.colorLinks.length >= 1) {
      this.productConfigForm.addControl(
        'color',
        new FormControl(
          presetColor !== false ? presetColor : '',
          this.productDoc.colorLinks.length >= 1 && [Validators.required]
        )
      );
    }

    this.authService.user$.pipe(take(1)).subscribe(async (user: User) => {
      // Create a references to the user's cart doc for various purposes
      if (user) {
        if (user.userType !== 'customer') {
          // inform only customer can do this operation
          const alert = await this.alertController.create({
            header: 'Incorrect User Type',
            message: 'Only a customer can add items to cart!',
            backdropDismiss: false,
            buttons: [
              {
                text: 'Go To Storefront',
                handler: (data) => {
                  this.viewCtrl.dismiss();
                  this.router.navigateByUrl('/catalog/home');
                },
              },
            ],
          });
          await alert.present();
          return;
        }
      }
    });

    this.loading = true;

  }

  ngAfterViewInit() {}
}
