// Core+
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

// Firebase/AngularFire
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire/compat';

// Services
import { AuthService } from './services/auth/auth.service';

// Service Worker
import { ServiceWorkerModule } from '@angular/service-worker';

// Modals
import { ConfirmProductPageModule } from './modals/confirm-product/confirm-product.module';
import { CartItemDetailPageModule } from './modals/cart-item-detail/cart-item-detail.module';
import { CartConfigPageModule } from './modals/cart-config/cart-config.module';
import { AddAdminUserPageModule } from './modals/add-admin-user/add-admin-user.module';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {GoogleMapsModule} from '@angular/google-maps';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { EditCategoryPageModule } from './modals/edit-category/edit-category.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import * as firebase from 'firebase';
//
// firebase.initializeApp(environment.firebase);
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
    imports: [
      AngularFireModule.initializeApp(environment.firebase),
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
        IonicModule.forRoot(),
      HttpClientModule,
      GoogleMapsModule,
        GooglePlaceModule,
        AppRoutingModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAnalytics(() => getAnalytics()),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        providePerformance(() => getPerformance()),
        provideStorage(() => getStorage()),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        ReactiveFormsModule,
        ConfirmProductPageModule,
        CartItemDetailPageModule,
        CartConfigPageModule,
         EditCategoryPageModule,
        AddAdminUserPageModule,
        SharedModule,
        BrowserAnimationsModule,
    ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        ScreenTrackingService,
        UserTrackingService,
        AuthService,
    ],
  bootstrap: [AppComponent]
})
export class AppModule {}
