<ion-header
  [translucent]="true"
  scrollX="true"
  scrollY="true"
  class="modalHeader"
>
  <ion-toolbar mode="md">
    <ion-title class="ion-no-margin">
      <ion-text color="primary" class="ion-text-center">
        <h2>{{category.title}}</h2>
      </ion-text>
    </ion-title>
    <ion-buttons slot="start" mode="ios" class="backButton">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close" color="secondary"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Category editing section -->
  <form [formGroup]="categoryConfigForm" class="modalForm">
    <!-- Dev Content, remove when converted -->
    <!-- category = {
        imageLink:
        'https://firebasestorage.googleapis.com/v0/b/instore-kiosk.appspot.com/o/categoryPhotos%2F1632074070320_0-Digital-Flip-Book_iPad_Category.jpg?alt=media&token=3f8960c6-1eac-41ae-9037-5ce685e56c80',
        title: 'iPad Prices',
        hasAsp: true,
        subTitle: 'Refurbished Devices',
        order: '2',
        docId: 'Na97l5IRVGuh6CtW0dxH',
     };
     -->
    <ion-item>
      <ion-label position="fixed">
        <h2><strong>Title:</strong></h2>
      </ion-label>
      <ion-input
        placeholder="Title"
        type="text"
        formControlName="title"
        [value]="category.title"
        required
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="fixed">
        <h2><strong>Subtitle:</strong></h2>
      </ion-label>
      <ion-input
        placeholder="Subtitle"
        type="text"
        formControlName="subTitle"
        [value]="category.subTitle"
        required
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-label>
        <h2><strong>Display Order:</strong></h2>
      </ion-label>
      <ion-input
        placeholder="#"
        type="number"
        min="0"
        formControlName="order"
        [value]="category.order"
        required
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-label>
        <h2><strong>Enable ASPs?:</strong></h2>
      </ion-label>
      <ion-toggle
        color="primary"
        formControlName="hasAsp"
        [value]="category.hasAsp"
        required
      ></ion-toggle>
    </ion-item>

    <!-- Add an image upload button to replace this input (DONE) -->
    <!-- <ion-item>
            <ion-label position="fixed">
                <h2>Image</h2>
            </ion-label>
            <ion-input type="text"
                formControlName="subtitle"
                [value]="category.imageLink"></ion-input>
        </ion-item> -->

    <!-- Display the existing category cover image -->
    <img
      [src]="category.imageLink"
      [alt]="category.title"
      *ngIf="categoryConfigForm.controls.imageLink.value === null"
    />
    <!-- Preview the new image that will be uploaded if the user submits the form -->
    <p *ngIf="previewCatiImageUrl"><sub>Preview:</sub></p>
    <img
      [src]="previewCatiImageUrl"
      [alt]="category.title"
      *ngIf="previewCatiImageUrl"
    />

    <ion-item>
      <ion-label>
        <h2><strong>Replace Cover Image?</strong></h2>
        <h3>(PNG, JPG, or SVG)</h3>
      </ion-label>
      <ion-input
        formControlName="imageLink"
        (change)="onCatiImageChange($event)"
        type="file"
        accept="image/png, image/jpeg, image/svg+xml"
      ></ion-input>
    </ion-item>
    <ion-progress-bar
      [color]="uploadCatiImagePercent < 100 ? 'primary' : 'success'"
      [value]="uploadCatiImagePercent"
      *ngIf="uploadCatiImagePercent > 0"
    ></ion-progress-bar>

    <br />

    <!-- Product editing section -->
    <section class="productsSection">
      <ion-label class="spreadFlex">
        <h1 slot="start"><strong>Products</strong></h1>

        <ion-button
          color="secondary"
          slot="end"
          (click)="toggleDropped($event.target)"
          class="button"
        >
          <ion-icon
            class="toggleSection"
            name="caret-down-outline"
            slot="icon-only"
          ></ion-icon>
        </ion-button>
      </ion-label>

      <div class="products">
        <div
          *ngFor="let product of productDocs | async"
          class="product"
          [id]="product.docId"
        >
          <div class="spreadFlex">
            <h5 slot="start">
              {{product.title.length > 0 ? product.title : "Untitled Product"}}
            </h5>
            <div class="buttonFlex">
              <ion-button
                color="primary"
                (click)="removeProduct(product)"
                class="button"
              >
                <ion-icon name="trash-outline" slot="icon-only"></ion-icon>
              </ion-button>
              <ion-button
                color="secondary"
                (click)="toggleDropped($event.target, true)"
                class="button"
              >
                <ion-icon
                  class="toggle"
                  name="caret-down-outline"
                  slot="icon-only"
                ></ion-icon>
              </ion-button>
            </div>
          </div>

          <div class="content" *ngIf="isDropped(product.docId)">
            <form
              [formGroup]="productBaseForms[product.docId].form"
              (ngSubmit)="submitProductBaseForm(product.docId, product.primaryImageLink)">
              <!-- TITLE -->
              <ion-item>
                <ion-label position="fixed">
                  <p>Title:</p>
                </ion-label>
                <ion-input
                  type="text"
                  placeholder="Title"
                  formControlName="title"
                  [value]="product.title"
                ></ion-input>
                <!-- (ionChange)="changeProp(product,
                                'title', $event.target, 32)" -->
              </ion-item>

              <!-- Display order -->
              <ion-item>
                <ion-label>
                  <p>Display Order:</p>
                </ion-label>
                <ion-input
                  type="number"
                  min="0"
                  placeholder="#"
                  formControlName="order"
                  [value]="product.order">
                </ion-input>
                <!-- (ionChange)="changeProp(product,
                                    'order', $event.target, 32)" -->
              </ion-item>

              <!-- Special Note -->
              <ion-item>
                <ion-label position="fixed">
                  <p>Note:</p>
                </ion-label>
                <ion-input
                  type="text"
                  placeholder="Note"
                  formControlName="note"
                  [value]="product.note"
                ></ion-input>
                <!-- (ionChange)="changeProp(product,
                                  'note', $event.target, 32)" -->
              </ion-item>

              <!-- Primary Image Link -->
              <ion-item>
                <ion-label>
                  <!-- <p>Primary Image</p> -->
                  <p><strong>Replace Primary Image?</strong></p>
                  <p>(PNG, JPG, or SVG)</p>
                </ion-label>
                <!-- Display the existing primary image for the product -->
                <ion-img
                  slot="start"
                  class="objectImage"
                  [src]="product.primaryImageLink"
                  [alt]="product.title"
                  *ngIf="productBaseForms[product.docId].form.controls.primaryImageLink.value === null"
                ></ion-img>
                <!-- Preview the new image that will be uploaded if the user submits the form -->
                <ion-img
                  slot="start"
                  class="objectImage"
                  [src]="productBaseForms[product.docId].images[0].previewUrl"
                  [alt]="product.title"
                  *ngIf="productBaseForms[product.docId].images[0].previewUrl"
                ></ion-img>

                <ion-input
                  formControlName="primaryImageLink"
                  (change)="onProductBaseImageChange($event, product.docId)"
                  type="file"
                  accept="image/png, image/jpeg, image/svg+xml"
                ></ion-input>
              </ion-item>
              <ion-progress-bar
                [color]="productBaseForms[product.docId].images[0].uploadPercent < 100 ? 'primary' : 'success'"
                [value]="productBaseForms[product.docId].images[0].uploadPercent"
                *ngIf="productBaseForms[product.docId].images[0].uploadPercent > 0"
              ></ion-progress-bar>

              <ion-button
                slot="end"
                block
                color="primary"
                type="submit"
                [disabled]="productBaseForms[product.docId].form.invalid"
              >
                Save
              </ion-button>
            </form>

            <!-- DETAILS -->
            <div class="modifiableSection">
              <ion-label class="spreadFlex">
                <h2 slot="start">Product Details</h2>
                <ion-button
                  color="secondary"
                  slot="end"
                  (click)="toggleDropped($event.target)"
                  class="button"
                >
                  <ion-icon
                    class="toggle"
                    name="caret-down-outline"
                    slot="icon-only"
                  ></ion-icon>
                </ion-button>
              </ion-label>

              <ion-list class="list">
                <div
                  *ngFor="let detail of
                                    product.details; let i = index"
                  class="listObject"
                >
                  <ion-img
                    slot="start"
                    class="objectImage"
                    [src]="detail.iconLink"
                  ></ion-img>
                  <ion-list slot="end" class="data">
                    <ion-item>
                      <ion-label position="fixed">
                        <p>Name</p>
                      </ion-label>
                      <ion-input
                        (ionChange)="changeProp(detail,
                                                'detailName', $event.target)"
                        type="text"
                        placeholder="Name"
                        [value]="detail.detailName"
                      ></ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="fixed">
                        <p>Icon Link</p>
                      </ion-label>
                      <ion-input
                        type="text"
                        placeholder="Icon Link
                                                (https://....)"
                        (ionChange)="changeProp(detail,
                                                'iconLink', $event.target)"
                        [value]="detail.iconLink"
                      ></ion-input>
                    </ion-item>
                  </ion-list>
                  <div class="buttonFlex alwaysVertical">
                    <ion-button
                      color="primary"
                      (click)="removeProductDetail(i, product)"
                      class="button"
                      disabled
                    >
                      <ion-icon
                        name="trash-outline"
                        slot="icon-only"
                      ></ion-icon>
                    </ion-button>
                  </div>
                </div>

                <ion-button
                  color="primary"
                  expand="block"
                  class="button"
                  disabled
                  (click)="addProp(product.details,
                                    emptyDetail)"
                >
                  Add Detail
                </ion-button>
              </ion-list>
            </div>

            <!-- IMAGE LINKS -->
            <div class="modifiableSection">
              <ion-label class="spreadFlex">
                <h2 slot="start">Image Links</h2>
                <ion-button
                  color="secondary"
                  slot="end"
                  (click)="toggleDropped($event.target)"
                  class="button"
                >
                  <ion-icon
                    class="toggle"
                    name="caret-down-outline"
                    slot="icon-only"
                  ></ion-icon>
                </ion-button>
              </ion-label>

              <!-- <ion-list class="list">
                <div
                  *ngFor="let imageLink of
                                    product.imageLinks"
                  class="listObject"
                >
                  <ion-img
                    slot="start"
                    class="objectImage"
                    [src]="imageLink"
                  ></ion-img>
                  <ion-list slot="end" class="data">
                    <ion-item>
                      <ion-label position="fixed">
                        <p>Image Link</p>
                      </ion-label>
                      <ion-input
                        (ionChange)="changeProp(product.imageLinks,
                                                product.imageLinks.indexOf(imageLink),
                                                $event.target)"
                        type="text"
                        placeholder="Link"
                        [value]="imageLink"
                      ></ion-input>
                    </ion-item>
                  </ion-list>
                  <div class="buttonFlex alwaysVertical">
                    <ion-button
                      color="primary"
                      (click)="removeProp(product.imageLinks,
                                            imageLink)"
                      class="button"
                    >
                      <ion-icon
                        name="trash-outline"
                        slot="icon-only"
                      ></ion-icon>
                    </ion-button>
                  </div>
                </div>

                <ion-button
                  color="primary"
                  expand="block"
                  class="button"
                  (click)="addProp(product.imageLinks,
                                    '')"
                >
                  Add Image Link
                </ion-button>
              </ion-list> -->

              <ion-list class="list">
                <form
                  [formGroup]="productImageForms[product.docId].form"
                  (ngSubmit)="submitProductImageForm(product.docId, product.imageLinks)"
                >
                  <div
                    *ngFor="let imageLink of
                    product.imageLinks; let i = index"
                    class="listObject"
                  >
                    <ion-item>
                      <ion-label>
                        <p><strong>Replace Product Image?</strong></p>
                        <p>(PNG, JPG, or SVG)</p>
                      </ion-label>
                      <!-- Display the existing image for the product -->
                      <ion-img
                        slot="start"
                        class="objectImage"
                        [src]="imageLink"
                        [alt]="product.title + ' - Image ' + i"
                        *ngIf="productImageForms[product.docId].form.controls['imageLink_' + i] !== undefined && productImageForms[product.docId].form.controls['imageLink_' + i].value === null"
                      ></ion-img>
                      <!-- Preview the new image that will be uploaded if the user submits the form -->
                      <ion-img
                        slot="start"
                        class="objectImage"
                        [src]="productImageForms[product.docId].images[i].previewUrl"
                        [alt]="product.title + ' - Color ' + i"
                        *ngIf="productImageForms[product.docId].images[i] !== undefined && productImageForms[product.docId].images[i].previewUrl"
                      ></ion-img>

                      <ion-input
                        (change)="onProductImageChange($event, product.docId, i)"
                        type="file"
                        [formControlName]="'imageLink_' + i"
                        accept="image/png, image/jpeg, image/svg+xml"
                        [disabled]="addingImage"
                      ></ion-input>
                    </ion-item>
                    <ion-progress-bar
                      [color]="productImageForms[product.docId].images[i].uploadPercent < 100 ? 'primary' : 'success'"
                      [value]="productImageForms[product.docId].images[i].uploadPercent"
                      *ngIf="productImageForms[product.docId].images[i] !== undefined && productImageForms[product.docId].images[i].uploadPercent > 0"
                    ></ion-progress-bar>

                    <div
                      class="buttonFlex alwaysVertical"
                      *ngIf="productImageForms[product.docId].form.controls['imageLink_' + i] !== undefined && !(productImageForms[product.docId].form.controls['imageLink_' + i].value === null)"
                    >
                      <ion-button
                        color="primary"
                        (click)="resetProductFile(product.docId, i)"
                        class="button"
                        [disabled]="addingImage"
                      >
                        <ion-icon
                          name="refresh-outline"
                          slot="icon-only"
                        ></ion-icon>
                      </ion-button>
                    </div>
                    <div
                      class="buttonFlex alwaysVertical"
                      *ngIf="productImageForms[product.docId].form.controls['imageLink_' + i] !== undefined && productImageForms[product.docId].form.controls['imageLink_' + i].value === null"
                    >
                      <ion-button
                        color="primary"
                        (click)="removeProductImage(product.docId, product.imageLinks, i)"
                        class="button"
                        [disabled]="addingImage"
                      >
                        <ion-icon
                          name="trash-outline"
                          slot="icon-only"
                        ></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                  <ion-button
                    block
                    color="primary"
                    type="submit"
                    [disabled]="productImageForms[product.docId].form.pristine || addingImage"
                    [hidden]="productImageForms[product.docId].images.length <= 0"
                  >
                    Save
                  </ion-button>
                </form>

                <form
                  [formGroup]="productNewImageForms[product.docId].form"
                  (ngSubmit)="submitNewProductImageForm(product)"
                  *ngIf="addingImage"
                >
                  <div class="listObject">
                    <ion-item>
                      <ion-label>
                        <p><strong>Upload New Product Image?</strong></p>
                        <p>(PNG, JPG, or SVG)</p>
                      </ion-label>

                      <!-- Preview the new image that will be uploaded if the user submits the form -->
                      <ion-img
                        slot="start"
                        class="objectImage"
                        [src]="productNewImageForms[product.docId].images[0].previewUrl"
                        [alt]="product.title + ' New Image'"
                        *ngIf="productNewImageForms[product.docId].images[0].previewUrl"
                      ></ion-img>

                      <ion-input
                        (change)="onProductNewImageChange($event, product.docId, 0)"
                        type="file"
                        formControlName="newImageLink"
                        accept="image/png, image/jpeg, image/svg+xml"
                        required
                      ></ion-input>
                    </ion-item>
                    <ion-progress-bar
                      [color]="productNewImageForms[product.docId].images[0].uploadPercent < 100 ? 'primary' : 'success'"
                      [value]="productNewImageForms[product.docId].images[0].uploadPercent"
                      *ngIf="productNewImageForms[product.docId].images[0].uploadPercent > 0"
                    ></ion-progress-bar>

                    <div
                      class="buttonFlex alwaysVertical"
                      *ngIf="!(productNewImageForms[product.docId].form.controls['newImageLink'].value === null)"
                    >
                      <ion-button
                        color="primary"
                        (click)="resetNewProductFile(product.docId, 0)"
                        class="button"
                      >
                        <ion-icon
                          name="refresh-outline"
                          slot="icon-only"
                        ></ion-icon>
                      </ion-button>
                    </div>
                  </div>

                  <ion-button
                    slot="end"
                    block
                    color="primary"
                    type="submit"
                    [disabled]="productNewImageForms[product.docId].form.invalid"
                  >
                    Add
                  </ion-button>
                </form>

                <ion-button
                  color="primary"
                  expand="block"
                  class="button"
                  (click)="addingImage = true"
                  *ngIf="!addingImage"
                >
                  New Product Image
                </ion-button>
              </ion-list>
            </div>

            <!-- COLOR LINKS -->
            <div class="modifiableSection">
              <ion-label class="spreadFlex">
                <h2 slot="start">Color Links</h2>
                <ion-button
                  color="secondary"
                  slot="end"
                  (click)="toggleDropped($event.target)"
                  class="button"
                >
                  <ion-icon
                    class="toggle"
                    name="caret-down-outline"
                    slot="icon-only"
                  ></ion-icon>
                </ion-button>
              </ion-label>

              <ion-list class="list">
                <form
                  [formGroup]="productColorForms[product.docId].form"
                  (ngSubmit)="submitProductColorForm(product.docId, product.colorLinks)"
                >
                  <div
                    *ngFor="let colorLink of
                                    product.colorLinks; let i = index"
                    class="listObject"
                  >
                    <ion-item>
                      <ion-label>
                        <p><strong>Replace Color Image?</strong></p>
                        <p>(PNG, JPG, or SVG)</p>
                      </ion-label>
                      <!-- Display the existing primary image for the product -->
                      <ion-img
                        slot="start"
                        class="objectImage"
                        [src]="colorLink"
                        [alt]="product.title + ' - Color ' + i"
                        *ngIf="productColorForms[product.docId].form.controls['colorLink_' + i] !== undefined && productColorForms[product.docId].form.controls['colorLink_' + i].value === null"
                      ></ion-img>
                      <!-- Preview the new image that will be uploaded if the user submits the form -->
                      <ion-img
                        slot="start"
                        class="objectImage"
                        [src]="productColorForms[product.docId].images[i].previewUrl"
                        [alt]="product.title + ' - Color ' + i"
                        *ngIf="productColorForms[product.docId].images[i] !== undefined && productColorForms[product.docId].images[i].previewUrl"
                      ></ion-img>

                      <ion-input
                        (change)="onProductColorImageChange($event, product.docId, i)"
                        type="file"
                        [formControlName]="'colorLink_' + i"
                        accept="image/png, image/jpeg, image/svg+xml"
                        [disabled]="addingColor"
                      ></ion-input>
                    </ion-item>
                    <ion-progress-bar
                      [color]="productColorForms[product.docId].images[i].uploadPercent < 100 ? 'primary' : 'success'"
                      [value]="productColorForms[product.docId].images[i].uploadPercent"
                      *ngIf="productColorForms[product.docId].images[i] !== undefined && productColorForms[product.docId].images[i].uploadPercent > 0"
                    ></ion-progress-bar>

                    <!-- <ion-img
                    slot="start"
                    class="objectImage"
                    [src]="colorLink"
                  ></ion-img>
                  <ion-list slot="end" class="data">
                    <ion-item>
                      <ion-label position="fixed">
                        <p>Image Link</p>
                      </ion-label>
                      <ion-input
                        (ionChange)="changeProp(product.colorLinks,
                                                product.colorLinks.indexOf(colorLink),
                                                $event.target)"
                        type="text"
                        placeholder="Link"
                        [value]="colorLink"
                      ></ion-input>
                    </ion-item>
                  </ion-list> -->
                    <div
                      class="buttonFlex alwaysVertical"
                      *ngIf="productColorForms[product.docId].form.controls['colorLink_' + i] !== undefined && !(productColorForms[product.docId].form.controls['colorLink_' + i].value === null)"
                    >
                      <ion-button
                        color="primary"
                        (click)="resetFile(product.docId, i)"
                        class="button"
                        [disabled]="addingColor"
                      >
                        <ion-icon
                          name="refresh-outline"
                          slot="icon-only"
                        ></ion-icon>
                      </ion-button>
                    </div>
                    <div
                      class="buttonFlex alwaysVertical"
                      *ngIf="productColorForms[product.docId].form.controls['colorLink_' + i] !== undefined && productColorForms[product.docId].form.controls['colorLink_' + i].value === null"
                    >
                      <ion-button
                        color="primary"
                        (click)="removeProductColor(product.docId, product.colorLinks, i)"
                        class="button"
                        [disabled]="addingColor"
                      >
                        <ion-icon
                          name="trash-outline"
                          slot="icon-only"
                        ></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                  <ion-button
                    block
                    color="primary"
                    type="submit"
                    [disabled]="productColorForms[product.docId].form.pristine || addingColor"
                    [hidden]="productColorForms[product.docId].images.length <= 0"
                  >
                    Save
                  </ion-button>
                </form>

                <form
                  [formGroup]="productNewColorForms[product.docId].form"
                  (ngSubmit)="submitNewProductColorForm(product)"
                  *ngIf="addingColor"
                >
                  <div class="listObject">
                    <ion-item>
                      <ion-label>
                        <p><strong>Upload New Color Image?</strong></p>
                        <p>(PNG, JPG, or SVG)</p>
                      </ion-label>

                      <!-- Preview the new image that will be uploaded if the user submits the form -->
                      <ion-img
                        slot="start"
                        class="objectImage"
                        [src]="productNewColorForms[product.docId].images[0].previewUrl"
                        [alt]="product.title + ' New Color'"
                        *ngIf="productNewColorForms[product.docId].images[0].previewUrl"
                      ></ion-img>

                      <ion-input
                        (change)="onProductNewColorImageChange($event, product.docId, 0)"
                        type="file"
                        formControlName="newColorLink"
                        accept="image/png, image/jpeg, image/svg+xml"
                        required
                      ></ion-input>
                    </ion-item>
                    <ion-progress-bar
                      [color]="productNewColorForms[product.docId].images[0].uploadPercent < 100 ? 'primary' : 'success'"
                      [value]="productNewColorForms[product.docId].images[0].uploadPercent"
                      *ngIf="productNewColorForms[product.docId].images[0].uploadPercent > 0"
                    ></ion-progress-bar>

                    <div
                      class="buttonFlex alwaysVertical"
                      *ngIf="!(productNewColorForms[product.docId].form.controls['newColorLink'].value === null)"
                    >
                      <ion-button
                        color="primary"
                        (click)="resetNewColorFile(product.docId, 0)"
                        class="button"
                      >
                        <ion-icon
                          name="refresh-outline"
                          slot="icon-only"
                        ></ion-icon>
                      </ion-button>
                    </div>
                  </div>

                  <ion-button
                    slot="end"
                    block
                    color="primary"
                    type="submit"
                    [disabled]="productNewColorForms[product.docId].form.invalid"
                  >
                    Add
                  </ion-button>
                </form>

                <ion-button
                  color="primary"
                  expand="block"
                  class="button"
                  (click)="addingColor = true"
                  *ngIf="!addingColor"
                >
                  New Color Image
                </ion-button>
              </ion-list>
            </div>

            <!-- PRICES -->
            <div class="modifiableSection">
              <ion-label class="spreadFlex">
                <h2 slot="start">Prices and Notes</h2>
                <ion-button
                  color="secondary"
                  slot="end"
                  (click)="toggleDropped($event.target)"
                  class="button"
                >
                  <ion-icon
                    class="toggle"
                    name="caret-down-outline"
                    slot="icon-only"
                  ></ion-icon>
                </ion-button>
              </ion-label>

              <ion-list class="list">
                <form
                  [formGroup]="productPriceForms[product.docId].form"
                  (ngSubmit)="submitProductPriceForm(product.docId)"
                >
                  <div
                    *ngFor="let price of
                                    product.prices; let i = index"
                    class="listObject"
                  >
                    <ion-list slot="end" class="data">
                      <ion-item>
                        <ion-label position="fixed">
                          <p>Price:</p>
                        </ion-label>
                        <ion-input
                          min="0"
                          type="number"
                          placeholder="0.00"
                          [formControlName]="'price_'+i"
                          [value]="price.price"
                          [disabled]="addingPrice"
                          required
                        >
                          <ion-text color="primary">$</ion-text>
                        </ion-input>
                        <!-- (ionChange)="changeProp(price,
                                                    'price', $event.target)" -->
                      </ion-item>
                      <ion-item>
                        <ion-label position="fixed">
                          <p>Note?</p>
                        </ion-label>
                        <ion-input
                          type="text"
                          placeholder="Note"
                          [formControlName]="'note_'+i"
                          [value]="price.note"
                          [disabled]="addingPrice"
                        ></ion-input>
                        <!--  (ionChange)="changeProp(price,
                                                  'note', $event.target)" -->
                      </ion-item>
                    </ion-list>
                    <div class="buttonFlex alwaysVertical">
                      <ion-button
                        color="primary"
                        (click)="removeProductPrice(product.docId, i)"
                        class="button"
                        [disabled]="addingPrice"
                      >
                        <ion-icon
                          name="trash-outline"
                          slot="icon-only"
                        ></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                  <ion-button
                    slot="end"
                    block
                    color="primary"
                    type="submit"
                    [disabled]="productPriceForms[product.docId].form.invalid || addingPrice"
                  >
                    Save
                  </ion-button>
                </form>

                <form
                  [formGroup]="productNewPriceForms[product.docId].form"
                  (ngSubmit)="submitNewProductPriceForm(product)"
                  *ngIf="addingPrice"
                >
                  <div class="listObject">
                    <ion-list slot="end" class="data">
                      <ion-item>
                        <ion-label position="fixed">
                          <p>Price:</p>
                        </ion-label>
                        <ion-input
                          min="0"
                          type="number"
                          placeholder="0.00"
                          formControlName="newPrice"
                          value="0.00"
                          required
                        >
                          <ion-text color="primary">$</ion-text>
                        </ion-input>
                        <!-- (ionChange)="changeProp(price,
                                                    'price', $event.target)" -->
                      </ion-item>
                      <ion-item>
                        <ion-label position="fixed">
                          <p>Note?</p>
                        </ion-label>
                        <ion-input
                          type="text"
                          placeholder="Note"
                          formControlName="newNote"
                          value=""
                        ></ion-input>
                        <!--  (ionChange)="changeProp(price,
                                                  'note', $event.target)" -->
                      </ion-item>
                    </ion-list>
                    <div class="buttonFlex alwaysVertical">
                      <ion-button
                        color="primary"
                        (click)="addingPrice = false; productNewPriceForms[product.docId].form.reset()"
                        class="button"
                      >
                        <ion-icon
                          name="trash-outline"
                          slot="icon-only"
                        ></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                  <ion-button
                    slot="end"
                    block
                    color="primary"
                    type="submit"
                    [disabled]="productNewPriceForms[product.docId].form.invalid"
                  >
                    Add
                  </ion-button>
                </form>

                <ion-button
                  color="primary"
                  expand="block"
                  class="button"
                  (click)="addingPrice = true"
                  *ngIf="!addingPrice"
                >
                  New Price Tier
                </ion-button>
              </ion-list>
            </div>
          </div>
        </div>

        <ion-button
          color="primary"
          expand="block"
          class="button"
          (click)="addProduct()"
          disabled
        >
          Add Product
        </ion-button>
      </div>
    </section>

    <!-- Submit category changes (also closes modal) -->
    <ion-button
      color="primary"
      expand="block"
      [disabled]="!categoryConfigForm.valid || isUploading"
      (click)="submitCatiForm()"
      class="button"
    >
      Apply Changes
    </ion-button>
  </form>
</ion-content>
