import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {MapComponent} from './map/map.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {IonicModule} from "@ionic/angular";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {StoreCardComponent} from "../store-card/store-card.component";
import {FooterComponent} from "../footer/footer.component";



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [MapComponent, StoreCardComponent, FooterComponent],
  exports: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    IonicModule,
    GooglePlaceModule
  ]
})
export class SharedModule { }
