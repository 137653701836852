import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart-item-detail',
  templateUrl: './cart-item-detail.page.html',
  styleUrls: ['./cart-item-detail.page.scss'],
})
export class CartItemDetailPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
