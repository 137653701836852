import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocationService} from '../../services/location/location.service';
import {take} from 'rxjs/operators';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import {LocationDoc} from '../../models/location.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MapService} from '../../map.service';
import {Geolocation} from '@capacitor/geolocation';
import {ModalController, ToastController} from '@ionic/angular';
import {WindowService} from '../../services/window/window.service';
// import LatLngBounds = google.maps.LatLngBounds;
// import LatLng = google.maps.LatLng;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap) map: GoogleMap;
  selectedLocation: LocationDoc;
  isOpen = false;
  google = google.maps;
  coordinates;
  interval;
  options: google.maps.MapOptions = {
    center: {lat: 40, lng: -20},
    zoom: 4,
    styles: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      }
    ]
  };
  ready = false;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  public allLocations: LocationDoc[];


  constructor(private location: LocationService, public mapService: MapService,
              private modalCtrl: ModalController, public win: WindowService, private toast: ToastController) {
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit() {
    this.addMarkers();
  }


  async addMarkers() {
    this.location.allLocationDocs$.pipe(take(1)).subscribe(data => {
      // console.log('Locations Data', data);
      this.allLocations = _.filter(data, item => (!!item.geolocation));
      for (const location of this.allLocations) {
        if (location.geolocation) {
          this.markerPositions.push({
            lat: location.geolocation.latitude,
            lng: location.geolocation.longitude
          });
        }
      }

      try {
        this.options.center = new google.maps.LatLng(this.markerPositions[0].lat,
          this.markerPositions[0].lng);
      } catch (e) {
        console.log(e);
      }

      this.ready = true;
      this.getCurrentLocation();
    });
  }

  async getCurrentLocation() {
    try {
      this.coordinates = await Geolocation.getCurrentPosition();
      this.options.zoom = 8;
      this.allLocations = this.mapService.addDistanceToLoc(this.allLocations,
        this.coordinates.coords.latitude, this.coordinates.coords.longitude);
      const latLng = new google.maps.LatLng(this.coordinates.coords.latitude,
        this.coordinates.coords.longitude);
      this.map.panToBounds(new google.maps.LatLngBounds(latLng));
      this.options.center = latLng;
      this.map.centerChanged.subscribe(center => console.log(center));
      if (!this.interval) {
        this.interval = setInterval(() => this.getCurrentLocation(), 130000);
      }
    } catch (e) {
      this.options.zoom = 4;
      console.log('unable to get location!', e);
    }
  }

  openInfoWindow(marker: MapMarker, loc: google.maps.LatLngLiteral) {
    if (loc && loc.lat && loc.lng) {
      this.selectedLocation = _.find(this.allLocations, item => item.geolocation.latitude === loc.lat
        && item.geolocation.longitude === loc.lng);
      this.isOpen = this.mapService.setOpen(this.selectedLocation);
      this.infoWindow.open(marker);
    } else {
      alert(JSON.stringify(loc));
      this.presentToast(JSON.stringify(loc));
    }
  }

  async presentToast(message: string) {
    const toast = await this.toast.create({
      message,
      duration: 2000
    });
    await toast.present();
  }

  handleAddressChange(address: Address) {
    this.options.center = address.geometry.location;
    this.map.panToBounds(new google.maps.LatLngBounds(address.geometry.location));
    this.allLocations = this.mapService.addDistanceToLoc(this.allLocations,
      address.geometry.location.lat(), address.geometry.location.lng());
  }

  async selectStore(location: LocationDoc) {
    await this.modalCtrl.dismiss(location);
  }

  setOpen() {
    const zone = this.selectedLocation.timeZone;
    const current = moment().utcOffset(zone, false);
    let open;
    let close;
    if (current.day() === 0 && this.selectedLocation.sundayHours.isOpen) {
      open = moment(this.selectedLocation.sundayHours.openTime, 'hh:mm A').utcOffset(zone, true);
      close = moment(this.selectedLocation.sundayHours.closeTime, 'hh:mm A').utcOffset(zone, true);
    } else if (current.day() === 6 && this.selectedLocation.saturdayHours.isOpen) {
      open = moment(this.selectedLocation.saturdayHours.openTime, 'hh:mm A').utcOffset(zone, true);
      close = moment(this.selectedLocation.saturdayHours.closeTime, 'hh:mm A').utcOffset(zone, true);
    } else {
      open = moment(this.selectedLocation.weekdayHours.openTime, 'hh:mm A').utcOffset(zone, true);
      close = moment(this.selectedLocation.weekdayHours.closeTime, 'hh:mm A').utcOffset(zone, true);
    }

    this.isOpen = current.isAfter(open) && current.isBefore(close);
  }

  async myLocation() {
    if (!this.coordinates) {
      const approved = await this.mapService.getLocation();
      console.log('Approved', approved);
      if (approved) {
        await this.getCurrentLocation();
      }
    } else {
      this.allLocations = this.mapService.addDistanceToLoc(this.allLocations,
        this.coordinates.coords.latitude, this.coordinates.coords.longitude);
      this.map.panToBounds(new google.maps.LatLngBounds(new google.maps.LatLng(this.coordinates.coords.latitude,
        this.coordinates.coords.longitude)));
    }

  }

  async close() {
    await this.modalCtrl.dismiss(false);
  }

  phoneFormat(input) {
    if (!input || isNaN(input)) {
      return `input must be a number was sent ${input}`;
    }
    if (typeof (input) !== 'string') {
      input = input.toString();
    }
    if (input.length === 10) {
      return input.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
    } else if (input.length < 10) {
      return 'was not supplied enough numbers please pass a 10 digit number';
    } else if (input.length > 10) {
      return 'was supplied too many numbers please pass a 10 digit number';
    } else {
      return 'something went wrong';
    }
  }

  confirm() {

  }

  cancel() {

  }
}
