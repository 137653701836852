// Core+
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { User, userTypes } from 'src/app/models/user.model';

// Models
import { Category, Product, ProductDetail } from '../../models/catalog.model';
import { AuthService } from '../auth/auth.service';
import { Series } from '../../models/series.model';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private authService: AuthService,
  ) {}

  public categoryName = '';

  get allCategoryDocs$(): Observable<Category[]> {
    return this.authService.user$.pipe(
      mergeMap((user: User) => {
        // For Customers
        if (user === null || user.userType === userTypes.customer) {
          return this.afs
            .collection<Category>('categories', (ref) =>
              ref.where('isDisabled', '!=', true)
            )
            .snapshotChanges()
            .pipe(
              // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
              map((changes) => {
                // This will return an observable of an Array of categories
                const categories = changes.map((change) => {
                  const category: Category = {
                    ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
                  };
                  return category;
                });
                return categories;
              }),
              // Sort the results
              map((categories) => categories.sort((a, b) => a.order - b.order))
            );
          // For Admins
        } else {
          return this.afs
            .collection<Category>('categories')
            .snapshotChanges()
            .pipe(
              // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
              map((changes) => {
                // This will return an observable of an Array of categories
                const categories = changes.map((change) => {
                  const category: Category = {
                    ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
                  };
                  return category;
                });
                return categories;
              }),
              // Sort the results
              map((categories) => categories.sort((a, b) => a.order - b.order))
            );
        }
      })
    );
  }

  productDocs$(categoryId): Observable<Product[]> {
    return this.afs
      .collection<Product>('products', (ref) =>
        // Filter out any products which don't belong to the caller category
        ref.where('catId', '==', categoryId)
      )
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const products = changes.map((change) => {
            const product: Product = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return product;
          });
          products.sort((a, b) => a.order - b.order);
          return products;
        }),
        // Sort results by order
        map((products) => products.sort((a, b) => a.order - b.order))
      );
  }

  seriesDocs$(categoryId): Observable<Series[]> {
    const series: Series[] = [];
    return new Observable((observer: Observer<Series[]>) =>
      this.afs
        .collection('productSeries', (ref) =>
          ref.where('category', '==', categoryId)
        )
        .get()
        .subscribe((data) => {
          for (const s of data.docs) {
            series.push(s.data() as Series);
          }
          series.sort((a, b) => a.position - b.position);
          observer.next(series);
          observer.complete();
        })
    );
  }

  get allProductDocs$(): Observable<Product[]> {
    return this.afs
      .collection<Product>('products')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const products = changes.map((change) => {
            const product: Product = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return product;
          });
          return products;
        }),
        // Sort results alphabetically
        map((products) => products.sort((a, b) => a.order - b.order))
      );
  }

  get allSeriesDocs$(): Observable<Product[]> {
    return this.afs
      .collection<Product>('productSeries')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const seriesList = changes.map((change) => {
            const series: Product = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return series;
          });
          return seriesList;
        }),
        // Sort results alphabetically
        map((products) => products.sort((a, b) => a.order - b.order))
      );
  }

  // Return a specific category document
  categoryDoc$(docId: string): Observable<Category> {
    return this.afs
      .collection<Category>('categories')
      .doc(docId)
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((change) => {
          const category: Category = {
            ...change.payload.data(), // Adds properties to the object for any properties of the data object
          };

          if (!change.payload.exists) {
            // eslint-disable-next-line no-throw-literal
            throw {
              code: 1,
              message: `The requested doc doesn't exist`,
            };
          }

          return category;
        }),

        catchError((err) =>
          throwError({
            code: err.code ? err.code : 2,
            message: err.message
              ? err.message
              : `Doc doesn't exist or the current user doesn't have permission to access it`,
          })
        )
      );
  }

  // Return a specific product document
  productDoc$(docId: string): Observable<Product> {
    return this.afs
      .collection<Product>('products')
      .doc(docId)
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((change) => {
          const product: Product = {
            ...change.payload.data(), // Adds properties to the object for any properties of the data object
          };

          if (!change.payload.exists) {
            // eslint-disable-next-line no-throw-literal
            throw {
              code: 1,
              message: `The requested doc doesn't exist`,
            };
          }

          return product;
        }),

        catchError((err) =>
          throwError({
            code: err.code ? err.code : 2,
            message: err.message
              ? err.message
              : `Doc doesn't exist or the current user doesn't have permission to access it`,
          })
        )
      );
  }

  /**
   * Save Product SeriesModel to Database
   *
   * @param title - Title of the series
   * @param category - Category object of the series
   * @param products - Products array
   */
  saveProductSeries(title: string, category: Category, products: string[]) {
    const series = this.afs.collection('productSeries').add({
      title,
      categoryName: category.title,
      category: category.docId,
      products,
    });

    series.then((seriesRef) => {
      seriesRef.set(
        {
          docId: seriesRef.id,
        },
        { merge: true }
      );
    });
  }

  // Newly created. Not fully bugtested. 6/23/2022 during admin-ui-v2
  async createProduct(title: string, category: Category) {
    const product = this.afs.collection<Product>('products').add({
      title,
      order: 0,
      details: [],
      primaryImageLink: '',
      imageLinks: [],
      prices: [],
      catId: category.docId,
      docId: '',
      isDisabled: false,
    });

    await product.then((productRef) => {
      productRef.set(
        {
          docId: productRef.id,
        },
        { merge: true }
      );
    });
  }

  async updateSeries(series: Series[]) {
    for (const item of series) {
      await this.afs.collection('productSeries').doc(item.docId).update(item);
    }
  }

  async updateOneSeries(
    id: string,
    title: string,
    category: Category,
    products: string[]
  ) {
    const series = this.afs.collection('productSeries').doc(id);
    await series.update({
      title,
      categoryName: category.title,
      category: category.docId,
      products,
    });
  }

  async deleteSeries(seriesId) {
    await this.afs.collection('productSeries').doc(seriesId).delete();
  }

  async deleteProduct(product: Product) {
    await this.afs.collection<Product>('products').doc(product.docId).delete();
  }
}
