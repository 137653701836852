// Core+
import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';

// Services
import {AuthService} from './services/auth/auth.service';
import {WindowService} from './services/window/window.service';
import {MetaService} from './services/meta/meta.service';
import {FirebaseApp} from '@angular/fire/app';
import {User} from './models/user.model';
import {CartService} from './services/cart/cart.service';
import {DOCUMENT} from '@angular/common';

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// Dev Only
export const userType = ['guest', 'user', 'admin', 'superadmin'][3];

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  userType = userType;
  mobileNavOpen = false;
  showCart = true;
  public repairDropdown = false;
  public mobileRepairDropdown = false;
  public showDashboard = false;

  constructor(app: FirebaseApp, public authService: AuthService, private meta: MetaService,
              public router: Router, public win: WindowService, public cart: CartService,
              @Inject(DOCUMENT) private document: Document) {
  }


  toggleMobileNav = () => {
    this.mobileNavOpen = !this.mobileNavOpen;
  };

  toggleRepair() {
    this.repairDropdown = !this.repairDropdown;
    console.log(this.repairDropdown);
  }

  toggleRepairDropdown(e): void {
    this.toggleRepair();

    const popover = document.querySelector('.repairPopover') as HTMLElement;
    // popover.style.display = this.repairDropdown ? 'flex' : 'none';
    popover.style.opacity = this.repairDropdown ? '1' : '0';
    popover.style.pointerEvents = this.repairDropdown ? 'all' : 'none';

    if (this.mobileNavOpen) {
      document.querySelector<HTMLInputElement>('.mobile-nav').focus();
      if (this.repairDropdown) {
        const rect = e.target.getBoundingClientRect();
        popover.style.left = rect.left + 'px';
        popover.style.top = rect.bottom + 'px';
      }
    }
  }

  toggleMobileRepairDropdown(e): void {
    this.mobileRepairDropdown = !this.mobileRepairDropdown;

    const popover = document.querySelector(
      '.mobileRepairPopover'
    ) as HTMLElement;
    popover.style.display = this.mobileRepairDropdown ? 'flex' : 'none';
  }


  signIn() {
    this.mobileNavOpen = false;
    this.authService.userSignIn();
  }

  signOut() {
    this.mobileNavOpen = false;
    this.authService.signOut();
  }

  watchUser() {
    this.authService.user$.subscribe((user: User) => {
      this.showDashboard = false;
      if (user && user.userType === 'customer') {
        this.showCart = true;
        this.showDashboard = true;
      } else if (!user) {
        this.showCart = true;
      } else {
        this.showCart = false;
      }
    });
  }

  checkIfClickingRepairDropdown = (e) => {
    const button = this.document.querySelector('#trigger-button') as HTMLElement;
    const popover = this.document.querySelector('.repairPopover') as HTMLElement;
    if (e.target !== popover && e.target !== button) {
      this.repairDropdown = false;
    }

    popover.style.opacity = this.repairDropdown ? '1' : '0';
    popover.style.pointerEvents = this.repairDropdown ? 'all' : 'none';

    if (this.repairDropdown) {
      const rect = e.target.getBoundingClientRect();
      popover.style.left = rect.left + 'px';
      popover.style.top = rect.bottom + 'px';
    }
  };

  isDescendant(parent, child) {
    let node = child.parentNode;
    while (node) {
      if (node === parent) {
        return true;
      }

      // Traverse up to the parent
      node = node.parentNode;
    }

    // Go up until the root but couldn't find the `parent`
    return false;
  }

  ngOnInit() {
    if (this.win.isBrowser) {
      this.meta.loadKeywords();
      document.querySelector('.mobile-nav').addEventListener('blur', () => {
        this.mobileNavOpen = false;
      });
      this.watchUser();
      window.addEventListener('click', this.checkIfClickingRepairDropdown.bind(this));
    }
  }
}
