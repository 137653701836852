import { Component, OnInit } from '@angular/core';
import {WindowService} from "../services/window/window.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  section = {
    buy: false,
    contact: false,
    company: false
  };

  constructor(public win: WindowService) { }

  ngOnInit() {}

  toggleSection(section: string){
    this.section[section] = !this.section[section];
  }

}
