// Core+
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// Auth Guards
import { AuthGuard } from './guards/auth.guard';
import { CustomerAuthGuard } from './guards/customer-auth.guard';
import { AdminAuthGuard } from './guards/admin-auth.guard';
import { SuperAdminAuthGuard } from './guards/super-admin-auth.guard';

const routes: Routes = [
  // Redirect from a blank path
  {
    path: '',
    redirectTo: '/catalog/home',
    pathMatch: 'full',
  },

  // Catalog Pages
  {
    path: 'catalog',
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomePageModule),
      },
      {
        path: 'products/:id',
        loadChildren: () =>
          import('./products/products.module').then(
            (m) => m.ProductsPageModule
          ),
      },
      {
        path: 'product-details/:id',
        loadChildren: () =>
          import('./product-details/product-details.module').then(
            (m) => m.ProductDetailsPageModule
          ),
      },

      // Wildcard route if nonsense is entered for child route but root path is good
      { path: '**', redirectTo: '/catalog/home' },
    ],
  },

  // Profile Pages
  {
    path: 'profile',
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./profile-home/profile-home.module').then(
            (m) => m.ProfileHomePageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./profile-orders/profile-orders.module').then(
            (m) => m.ProfileOrdersPageModule
          ),
        canActivate: [CustomerAuthGuard],
      },
      {
        path: 'orders/help',
        loadChildren: () =>
          import('./profile-orders-help/profile-orders-help.module').then(
            (m) => m.ProfileOrdersHelpPageModule
          ),
        canActivate: [CustomerAuthGuard],
      },

      // Wildcard route if nonsense is entered for child route but root path is good
      { path: '**', redirectTo: '/profile/dashboard' },
    ],
  },

  // Admin and Super Admin Pages
  {
    path: 'admin',
    children: [
      // Admin Pages
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./admin-dashboard/admin-dashboard.module').then(
            (m) => m.AdminDashboardPageModule
          ),
        canActivate: [AdminAuthGuard],
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./admin-orders/admin-orders.module').then(
            (m) => m.AdminOrdersPageModule
          ),
        canActivate: [AdminAuthGuard],
      },
      {
        path: 'prep',
        loadChildren: () =>
          import('./admin-prep/admin-prep.module').then(
            (m) => m.AdminPrepPageModule
          ),
        canActivate: [AdminAuthGuard],
      },

      // Super Admin Pages
      {
        path: 'catalog-add',
        loadChildren: () =>
          import('./admin-catalog-add/admin-catalog-add.module').then(
            (m) => m.AdminCatalogAddPageModule
          ),
        canActivate: [SuperAdminAuthGuard],
      },
      {
        path: 'series-add',
        loadChildren: () =>
          import('./admin-series-add/admin-series-add.module').then(
            (m) => m.AdminSeriesAddPageModule
          ),
        canActivate: [SuperAdminAuthGuard],
      },
      {
        path: 'config',
        loadChildren: () =>
          import('./admin-config/admin-config.module').then(
            (m) => m.AdminConfigPageModule
          ),
        canActivate: [SuperAdminAuthGuard],
      },
      {
        path: 'storefront-manager',
        loadChildren: () =>
          import(
            './admin-storefront-manager/admin-storefront-manager.module'
          ).then((m) => m.AdminStorefrontManagerPageModule),
        canActivate: [SuperAdminAuthGuard],
      },

      // Added dynamic route that takes in the ID of the category that you want to edit.
      {
        path: 'category-editor/:docId',
        loadChildren: () =>
          import('./admin-category-editor/admin-category-editor.module').then(
            (m) => m.AdminCategoryEditorPageModule
          ),
        canActivate: [SuperAdminAuthGuard],
      },

      // Added another dynamic route for series editing.
      {
        path: 'series-editor/:categoryId/:seriesId',
        loadChildren: () =>
          import('./admin-series-editor/admin-series-editor.module').then(
            (m) => m.AdminSeriesEditorPageModule
          ),
        canActivate: [SuperAdminAuthGuard],
      },

      // Route for product editor:
      {
        path: 'product-editor/:productId',
        loadChildren: () =>
          import('./admin-product-editor/admin-product-editor.module').then(
            (m) => m.AdminProductEditorPageModule
          ),
        canActivate: [SuperAdminAuthGuard],
      },

      // Wildcard route if nonsense is entered for child route but root path is good
      { path: '**', redirectTo: '/admin/dashboard' },
    ],
  },

  // Root Customer/Admin level pages
  {
    path: 'track',
    loadChildren: () =>
      import('./order-tracking/order-tracking.module').then(
        (m) => m.OrderTrackingPageModule
      ),
    // canActivate: [AuthGuard],
  },

  // Root Customer level pages
  {
    path: 'cart',
    loadChildren: () =>
      import('./cart/cart.module').then((m) => m.CartPageModule),
    // canActivate: [CustomerAuthGuard],
  },
  {
    path: 'check-out',
    loadChildren: () =>
      import('./check-out/check-out.module').then((m) => m.CheckOutPageModule),
    // canActivate: [CustomerAuthGuard],
  },

  // Root Guest level pages
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqPageModule),
  },
  {
    path: 'admin-sign-in',
    loadChildren: () =>
      import('./admin-sign-in/admin-sign-in.module').then(
        (m) => m.AdminSignInPageModule
      ),
  },

  // Wildcard route if nonsense is entered
  { path: '**', redirectTo: '/catalog/home' },
  {
    path: 'detail-image-selector',
    loadChildren: () => import('./modals/detail-image-selector/detail-image-selector.module').then( m => m.DetailImageSelectorPageModule)
  },
  {
    path: 'color-link-selector',
    loadChildren: () => import('./modals/color-link-selector/color-link-selector.module').then( m => m.ColorLinkSelectorPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled', anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

