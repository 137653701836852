<ion-app>
  <div [id]="'main-content'" class="ion-page">

    <div class="repairPopover">
      <a href="https://quickmobilerepair.com/iphone-repair/"
      >
        <button>IPHONE</button>
      </a
      >
      <a href="https://quickmobilerepair.com/samsung-repair/">
        <button>SAMSUNG</button>
      </a
      ><a href="https://quickmobilerepair.com/ipad-repair/">
      <button>IPAD</button>
    </a
    ><a href="https://quickmobilerepair.com/game-console-repair/">
      <button>GAME CONSOLE</button>
    </a
    ><a href="https://quickmobilerepair.com/android-repair/">
      <button>CELL PHONE</button>
    </a
    ><a href="https://quickmobilerepair.com/pc-repairs/">
      <button>COMPUTER</button>
    </a
    ><a href="https://quickmobilerepair.com/mac-repair/">
      <button>MAC</button>
    </a
    ><a href="https://quickmobilerepair.com/tablet-repair/">
      <button>TABLET</button>
    </a
    ><a href="https://quickmobilerepair.com/micro-soldering-repair/">
      <button>MICROSOLDERING</button>
    </a
    ><a href="https://quickmobilerepair.com/business-soldering-repair/">
      <button>B2B SOLDERING</button>
    </a>
    </div>

    <ion-header class="header-with-navs" mode="md">
      <ion-toolbar class="toolbar">
        <ion-title slot="start">
          <ion-img
            [routerLink]="['/catalog/home']"
            class="headerImg"
            src="../assets/icons/webLogo.png"
          ></ion-img>

          <!-- <img
                        *ngIf="screenWidth<640"
                        [routerLink]="['/catalog/home']"
                        src="../assets/icons/mobile-logo.svg"
                        class="headerImg" /> -->
        </ion-title>

        <ion-buttons
          *ngIf="win.screenWidth > 1410"
          class="buttons topnav"
          slot="end"
        >
          <ion-button
            [color]="router.url === '/catalog/home' ? 'primary' : 'secondary'"
            [routerLink]="['/catalog/home']"
            class="navbar-button"
            fill="clear"
            shape="round"
          >
            <ion-label>Products</ion-label>
          </ion-button>


          <ion-button
            (click)="toggleRepairDropdown($event)"
            class="navbar-button repair"
            color="secondary"
            fill="clear"
            id="trigger-button"
            shape="round"
          >
            <!-- <ion-label>Repair</ion-label> -->
            <ion-label style="pointer-events: none">repair</ion-label>
            <ion-icon
              name="caret-down-outline"
              style="pointer-events: none"
            ></ion-icon>
          </ion-button>

          <a href="https://quickmobilerepair.com/business-repair/">
            <ion-button
              class="navbar-button"
              color="secondary"
              fill="clear"
              shape="round">
              <ion-label>Corporate & EDU</ion-label>
            </ion-button>
          </a>

          <a href="https://quickmobilerepair.com/locations/">
            <ion-button
              class="navbar-button"
              color="secondary"
              fill="clear"
              shape="round"
            >
              <ion-label>Locations</ion-label>
            </ion-button>
          </a>

          <a href="https://quickmobilerepair.com/about-us/">
            <ion-button
              class="navbar-button"
              color="secondary"
              fill="clear"
              shape="round"
            >
              <ion-label>About</ion-label>
            </ion-button>
          </a>

          <a href="https://quickmobilerepair.com/now-hiring/">
            <ion-button
              class="navbar-button"
              color="secondary"
              fill="clear"
              shape="round"
            >
              <ion-label>Careers</ion-label>
            </ion-button>
          </a>


          <a href="https://quickmobilerepair.com/franchise/">
            <ion-button
              class="navbar-button"
              color="secondary"
              fill="clear"
              shape="round"
            >
              <ion-label>Franchise</ion-label>
            </ion-button>
          </a>

          <!--          <ion-button-->
          <!--            [color]="router.url === '/faq' ? 'primary' : 'secondary'"-->
          <!--            fill="clear"-->
          <!--            shape="round"-->
          <!--            [routerLink]="['/faq']"-->
          <!--            class="navbar-button"-->
          <!--          >-->
          <!--            <ion-label>FAQ</ion-label>-->
          <!--          </ion-button>-->

          <a href="https://quickmobilerepair.com/blog/">
            <ion-button
              class="navbar-button"
              color="secondary"
              fill="clear"
              shape="round"
            >
              <ion-label>Blog</ion-label>
            </ion-button>
          </a>

          <ion-button
            *ngIf="authService.user$ | async as user"
            [color]="
              router.url === '/profile/dashboard' ? 'primary' : 'secondary'
            "
            [routerLink]="['/profile/dashboard']"
            class="navbar-button"
            fill="clear"
            shape="round"
          >
            <ion-label>My Dashboard</ion-label>
          </ion-button>

          <span *ngIf="authService.user$ | async as user">
            <!-- ADMIN MENU -->
            <ion-button
              *ngIf="user.userType === 'admin'"
              [color]="
                router.url === '/admin/dashboard' ? 'primary' : 'secondary'
              "
              [routerLink]="['/admin/dashboard']"
              class="navbar-button"
              fill="clear"
              shape="round"
            >
              <ion-label>Admin Dashboard</ion-label>
            </ion-button>

            <!-- SUPERADMIN MENU -->
            <ion-button
              *ngIf="user.userType === 'superAdmin'"
              [color]="router.url === '/admin/dashboard' ? 'primary' : 'secondary'"
              [routerLink]="['/admin/dashboard']"
              class="navbar-button"
              fill="clear"
              shape="round"
            >
              <ion-label>SuperAdmin Dashboard</ion-label>
            </ion-button>
          </span>

          <div
            *ngIf="
              authService.user$ | async as user;
              then authMenuNav;
              else guestMenuNav
            "
          ></div>
        </ion-buttons>

        <ion-buttons
          *ngIf="win.screenWidth <= 1410"
          class="buttons topnav"
          slot="end"
        >
          <ion-button
            (click)="toggleMobileNav()"
            class="openMobileNavButton"
            color="primary"
            fill="clear"
            slot="end"
          >
            <ion-icon
              [name]="mobileNavOpen === false ? 'menu-outline' : 'close-outline'"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <div
        [ngClass]="{ visible: mobileNavOpen === true }"
        class="mobile-nav"
        tabindex="0"
      >
        <button
          (click)="mobileNavOpen = false"
          [routerLink]="['/catalog/home']"
          class="navbar-button"
        >
          <ion-label>Products</ion-label>
        </button>

        <button
          (click)="toggleMobileRepairDropdown($event)"
          class="navbar-button repair"
          color="secondary"
          fill="clear"
          shape="round"
        >
          <!-- <ion-label>Repair</ion-label> -->
          <ion-label style="pointer-events: none">repair</ion-label>
          <ion-icon
            name="caret-down-outline"
            style="pointer-events: none"
          ></ion-icon>
        </button>

        <div class="mobileRepairPopover">
          <a href="https://quickmobilerepair.com/iphone-repair/"
          >
            <button class="navbar-button">IPHONE</button>
          </a
          >
          <a href="https://quickmobilerepair.com/samsung-repair/">
            <button class="navbar-button">SAMSUNG</button>
          </a
          ><a href="https://quickmobilerepair.com/ipad-repair/">
          <button class="navbar-button">IPAD</button>
        </a
        ><a href="https://quickmobilerepair.com/game-console-repair/">
          <button class="navbar-button">GAME CONSOLE</button>
        </a
        ><a href="https://quickmobilerepair.com/android-repair/">
          <button class="navbar-button">CELL PHONE</button>
        </a
        ><a href="https://quickmobilerepair.com/pc-repairs/">
          <button class="navbar-button">COMPUTER</button>
        </a
        ><a href="https://quickmobilerepair.com/mac-repair/">
          <button class="navbar-button">MAC</button>
        </a
        ><a href="https://quickmobilerepair.com/tablet-repair/">
          <button class="navbar-button">TABLET</button>
        </a
        ><a href="https://quickmobilerepair.com/micro-soldering-repair/">
          <button class="navbar-button">MICROSOLDERING</button>
        </a
        ><a href="https://quickmobilerepair.com/business-soldering-repair/">
          <button class="navbar-button">B2B SOLDERING</button>
        </a>
        </div>

        <a href="https://quickmobilerepair.com/business-repair/">
          <button class="navbar-button">
            <ion-label>Corporate & EDU</ion-label>
          </button>
        </a>

        <a href="https://quickmobilerepair.com/locations/">
          <button class="navbar-button">
            <ion-label>Locations</ion-label>
          </button>
        </a>


        <a href="https://quickmobilerepair.com/about-us/">
          <button class="navbar-button">
            <ion-label>About</ion-label>
          </button>
        </a>

        <a href="https://quickmobilerepair.com/now-hiring/">
          <button class="navbar-button">
            <ion-label>Careers</ion-label>
          </button>
        </a>


        <a href="https://quickmobilerepair.com/franchise/">
          <button class="navbar-button">
            <ion-label>Franchise</ion-label>
          </button>
        </a>

        <a href="https://quickmobilerepair.com/blog/">
          <button class="navbar-button">
            <ion-label>Blog</ion-label>
          </button>
        </a>

        <!--        <button-->
        <!--          [routerLink]="['/faq']"-->
        <!--          class="navbar-button"-->
        <!--          (click)="mobileNavOpen = false"-->
        <!--        >-->
        <!--          <ion-label>FAQ</ion-label>-->
        <!--        </button>-->

        <!-- USER MENU -->
        <div class="user-menu">
          <button (click)="mobileNavOpen = false"
                  *ngIf="showDashboard"
                  [routerLink]="['/profile/dashboard']"
                  class="navbar-button">
            <ion-label>My Dashboard</ion-label>
          </button>
          <!--          <button-->
          <!--            [routerLink]="['/cart']"-->
          <!--            class="navbar-button"-->
          <!--            *ngIf="cart.cartLength > 0"-->
          <!--            (click)="mobileNavOpen = false">-->
          <!--            <ion-label>Cart</ion-label>-->
          <!--          </button>-->
          <!--          </button>          <button-->
          <!--            [routerLink]="['/cart']"-->
          <!--            class="navbar-button"-->
          <!--            *ngIf="user.userType == 'customer' && user.cart.items.length > 0"-->
          <!--            (click)="mobileNavOpen = false"-->
          <!--          >-->
          <!--            <ion-label>Cart {{cart.cartLength}}</ion-label>-->
          <!--          </button>-->

          <!-- ADMIN MENU -->
          <ng-template *ngIf="authService.user$ | async as user">
            <button
              (click)="mobileNavOpen = false"
              *ngIf="user.userType === 'admin'"
              [routerLink]="['/admin/dashboard']"
              class="navbar-button">
              <ion-label>Admin Dashboard</ion-label>
            </button>

            <!-- SUPERADMIN MENU -->
            <button
              (click)="mobileNavOpen = false"
              *ngIf="user.userType === 'superAdmin'"
              [routerLink]="['/admin/dashboard']"
              class="navbar-button">
              <ion-label>SuperAdmin Dashboard</ion-label>
            </button>

            <!-- MENU FOR BOTH ADMINS -->

            <button
              (click)="mobileNavOpen = false"
              *ngIf="user.userType === 'admin' || user.userType === 'superAdmin'"
              [routerLink]="['/admin/orders']"
              class="navbar-button">
              <ion-label>Pending Orders</ion-label>
            </button>
          </ng-template>

        </div>

        <div
          *ngIf="
            authService.user$ | async as user;
            then authMenuNavMobile;
            else guestMenuNavMobile
          "
        ></div>
      </div>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-router-outlet id="main-content">
        <div class="cartFloatButton">
          <ion-button
            *ngIf="cart.cartLength > 0 &&
              !router.url.includes('/cart') &&
              !router.url.includes('/check-out')
            "
            class="cart-button"
            routerLink="/cart"
            shape="round"
          >
            <ion-icon name="cart-outline" slot="start"></ion-icon>
            Cart
            <ion-badge color="light" style="margin-left: 0.45rem">
              {{ cart.cartLength }}
            </ion-badge>
          </ion-button>
        </div>
      </ion-router-outlet>
    </ion-content>
  </div>
</ion-app>

<ng-template #guestMenu>
  <ion-button
    (click)="authService.userSignIn()"
    class="navbar-button end-button"
    fill="clear"
    shape="round">
    <ion-icon name="log-in-outline" slot="start"></ion-icon>
    <ion-label>Sign In</ion-label>
  </ion-button>
</ng-template>

<ng-template #authMenu>
  <ion-button
    (click)="authService.signOut()"
    class="navbar-button end-button"
    fill="clear"
    shape="round">
    <ion-icon name="log-out-outline" slot="start"></ion-icon>
    <ion-label>Sign Out</ion-label>
  </ion-button>
</ng-template>

<ng-template #guestMenuNav>
  <ion-button
    (click)="authService.userSignIn()"
    class="navbar-button end-button"
    fill="clear"
    shape="round">
    <ion-label>Sign In</ion-label>
  </ion-button>
</ng-template>

<ng-template #authMenuNav>
  <ion-button
    (click)="authService.signOut()"
    class="navbar-button end-button"
    fill="clear"
    shape="round">
    <ion-label>Sign Out</ion-label>
  </ion-button>
</ng-template>

<ng-template #guestMenuNavMobile>
  <button (click)="authService.userSignIn()" class="navbar-button">
    <ion-label>Sign In</ion-label>
  </button>
</ng-template>

<ng-template #authMenuNavMobile>
  <button (click)="authService.signOut()" class="navbar-button">
    <ion-label>Sign Out</ion-label>
  </button>
</ng-template>

<ng-template #loggedOut>
  <ion-title slot="start">
    {{ win.screenWidth > 640 ? "QMR - Online Order System" : "QMR" }}
  </ion-title>
</ng-template>
