// Core+
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {switchMap, take, map, first} from 'rxjs/operators';
import { Router } from '@angular/router';

// Firebase/AngularFire
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

// Models
import { adminBuilder, User, userTypes } from '../../models/user.model';

// Operations
import { leftJoinDocument } from 'src/app/operations/collection-join';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<any>;
  user: User;

  constructor(
    private afStore: AngularFirestore,
    private ngFireAuth: AngularFireAuth,
    private router: Router,
    private functions: AngularFireFunctions,
    private alertController: AlertController
  ) {
    this.user$ = this.ngFireAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afStore
            .doc<User>(`users/${user.uid}`)
            .valueChanges()
            .pipe(
              // Prep user for collection joins (wrap user object in an array)
              map((user) => [user]),
              // Add linked docs to user doc
              leftJoinDocument(this.afStore, 'myStore', 'locations', 'myStore'),
              leftJoinDocument(this.afStore, 'cart', 'carts', 'cart'),
              // Convert user doc back to format needed (single object)
              map((userArr) => userArr[0]),
              // Sets linked doc properties to a blank string instead of null if there was no doc to pass
              map((user: User) => {
                if (user.myStore === null) {
                  user.myStore = '';
                }
                if (user.cart === null) {
                  user.cart = '';
                }

                this.user = user;
                return user;
              })
            );
        } else {
          return of(null);
        }
      })
    );
  }

  public async isLoggedIn() {
    return !!await this.ngFireAuth.authState.pipe(first()).toPromise();
  }

  // Return all of the orders in the database
  get allAdminUsers$(): Observable<any> {
    return (
      this.afStore
        .collection<User>('users', (ref) =>
          ref.where('userType', 'in', ['admin', 'superAdmin'])
        )
        // .collection<User>('users')
        .snapshotChanges()
        .pipe(
          // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
          map((changes) => {
            // This will return an observable of an Array of categories
            const users = changes.map((change) => {
              const user: User = {
                ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
              };
              return user;
            });
            return users;
          }),
          leftJoinDocument(this.afStore, 'myStore', 'locations', 'myStore'),
          leftJoinDocument(this.afStore, 'cart', 'carts', 'cart')
        )
    );
  }

  async userSignIn() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential: firebase.auth.UserCredential =
      await this.ngFireAuth.signInWithPopup(provider);
    // console.log(await credential.user.getIdTokenResult());

    const hasClaim = await credential.user
      .getIdTokenResult()
      .then((token) => token.claims.customer);

    if (!hasClaim) {
      return this.setInitalUserData(
        credential.user,
        credential.user.displayName
      ).then(async (res) => {
        // Signs the user out and prompts for re-signIn if it's their first time signing in or they don't have a proper claim

        if (!hasClaim) {
          this.signOut();

          const alert = await this.alertController.create({
            cssClass: 'signin-alert',
            header: 'Account Created!',
            message: 'You can now sign in!',
            buttons: ['OK'],
          });

          await alert.present();
        }
        return res;
      });
    } else {
      return this.setInitalUserData(credential.user).then(async (res) => {
        // Signs the user out and prompts for re-signIn if it's their first time signing in, or they don't have a proper claim

        if (!hasClaim) {
          this.signOut();
          const alert = await this.alertController.create({
            cssClass: 'signin-alert',
            header: 'Account Created!',
            message: 'You can now sign in!',
            buttons: ['OK'],
          });

          await alert.present();
        }
        return res;
      });
    }
  }

  // Login in with email/password (for admin and superAdmin level)
  async adminSignIn(email, password) {
    try {
      const credential = await this.ngFireAuth.signInWithEmailAndPassword(
        email,
        password
      );
      if (
        credential.user.emailVerified &&
        (await credential.user.getIdTokenResult()).claims.admin
      ) {
        this.setInitalUserData(credential.user);
        this.router.navigate(['admin']);
      } else {
        const alert = await this.alertController.create({
          cssClass: 'signin-alert',
          header: 'Uh oh!',
          message: 'Sign in disallowed, consult an admin!',
          buttons: ['OK'],
        });

        await alert.present();
        await this.signOut();
      }
    } catch (err) {
      this.genError(err);
    }
  }

  // Register user with email/password (for admin and superAdmin level)
  async registerAdminUser(
    email: string,
    password: string,
    displayName: string,
    userType: userTypes
  ) {
    // Prep the new user data for the server
    const serverPayload: adminBuilder = {
      userData: {
        email,
        userType,
        displayName,
      },
      password,
    };
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('buildAdmin');
    return buildAdminCallable(serverPayload);
  }

  // Recover password
  async passwordRecover(passwordResetEmail) {
    this.user$.pipe(take(1)).subscribe((user) => {
      try {
        this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail).then(() => {
          if (user.email === passwordResetEmail) {
            this.signOut();
          }
        });
      } catch (err) {
        this.genError(err);
      }
    });
  }

  async genError(message) {
    const alert = await this.alertController.create({
      cssClass: 'signin-alert',
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  // Sign-out
  signOut() {
    return this.ngFireAuth.signOut().then(() => {
      this.router.navigate(['catalog/home']);
    });
  }

  // Store user in firestore
  private async setInitalUserData(user: firebase.User, displayName?: string) {
    const userData: User = {
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
    };
    if (displayName) {
      userData.displayName = displayName;
    }

    // Send the data-set to the cloud function
    // This will determine if the data changes passed are allowed before setting them
    const setUserDataCallable = this.functions.httpsCallable('setUserData');
    return setUserDataCallable(userData).subscribe((res) => res, error => {
    });
  }

  // Change a portion of a user's data in Firestore
  async appendUserData(user: User) {
    // Send the submitted data-set to the cloud function
    // This will determine if the data changes passed are allowed before setting them
    const setUserDataCallable = this.functions.httpsCallable('setUserData');
    return setUserDataCallable(user);
  }

  grantSuperAdmin(email: string) {
    const grantSuperAdminRoleCallable =
      this.functions.httpsCallable('addSuperAdmin');
    return grantSuperAdminRoleCallable(email);
  }
  grantAdmin(email: string) {
    const grantAdminRoleCallable = this.functions.httpsCallable('addAdmin');
    return grantAdminRoleCallable(email);
  }

  revokeSuperAdmin(email: string) {
    const removeSuperAdminRoleCallable =
      this.functions.httpsCallable('removeSuperAdmin');
    return removeSuperAdminRoleCallable(email);
  }
  revokeAdmin(email: string) {
    const removeAdminRoleCallable = this.functions.httpsCallable('removeAdmin');
    return removeAdminRoleCallable(email);
  }

  deleteAdmin(email: string) {
    const deleteAccountRoleCallable =
      this.functions.httpsCallable('deleteAccount');
    return deleteAccountRoleCallable(email);
  }

  // This function likely isn't needed anymore.
  // Once proper UI is built, delete this if it ends up not being needed
  // // Email verification when new user register (for admin and superAdmin level)
  // async sendVerificationMail() {
  //   try {
  //     await (await this.ngFireAuth.currentUser).sendEmailVerification();
  //   } catch (err) {
  //     window.alert(err.message);
  //   }
  // }
   isUserExists(email: string) {
    const callable = this.functions.httpsCallable('isUserExists');
    const data$ = callable({ email });
    return data$.toPromise();
  }

  /**
   * Checks if current user is superadmin or not
   */
  public isSuperAdmin(): boolean{
    return this.user.userType === userTypes.superAdmin;
  }
}
