import {HostListener, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  public screenWidth = 800;
  public widthObserver = new BehaviorSubject(this.screenWidth);
  public screenSize = 'desktop';
  public isBrowser = false;
  public isMobile = false;
  public isTablet = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser){
      this.screenWidth = window.innerWidth;
      this.screenSize = window.innerWidth <=640 ? 'mobile': 'desktop';
      this.isMobile = this.screenWidth <= 640;
      this.isTablet = this.screenWidth < 900;
      this.widthObserver.next(this.screenWidth);
      window.onresize = () => {
        this.screenSize = window.innerWidth <= 640 ? 'mobile' : 'desktop';
        this.screenWidth = window.innerWidth;
        this.isMobile = this.screenWidth <= 640;
        this.isTablet = this.screenWidth < 900;
        this.widthObserver.next(this.screenWidth);
        // console.log('resized event', window.innerWidth, this.screenWidth, this.screenSize, this.isMobile);
      };
    }
  }
}
