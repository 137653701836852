<div class="footer">
  <div class="row" *ngIf="!win.isMobile">
    <div class="col">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-4">
          <h4 class="mb-3">Company</h4>
          <p><a>About Us</a></p>
          <p><a>Customer Services</a></p>
          <p><a>Privacy Policy</a></p>
          <p><a>Grading Details</a></p>
          <p><a>Trade</a></p>
          <p><a>Sitemap</a></p>
          <p><a>Blog</a></p>
          <p><a>Unlock Phones</a></p>
        </div>
        <div class="col-3">
          <h4 class="mb-3">Buy</h4>
          <p><a>Deals</a></p>
          <p><a>iPhone</a></p>
          <p><a>iPad</a></p>
          <p><a>Samsung</a></p>
          <p><a>Apple Watch</a></p>
          <p><a>Accessories</a></p>
        </div>
        <div class="col-3">
          <h4 class="mb-3">Contact Us</h4>
          <p><a>Customer Support</a></p>
          <p><a>sales@quickmobilerepair.com</a></p>
        </div>
      </div>
    </div>
    <div class="col ion-text-right">
      <div class="row">
        <div class="col-8">
          <div class="col"></div>
          <div class="col"></div>
          <div class="col"></div>
          <div class="col"></div>
        </div>
        <div class="col-4">
          <div class="footer-icons">
            <div class="row align-items-center">
              <div class="col-auto">
                <span class="mdi mdi-instagram filled-footer-icon"></span>
                <span class="mdi mdi-twitter filled-footer-icon"></span>
                <span class="mdi mdi-linkedin filled-footer-icon"></span>
              </div>
              <div class="col-auto">
                <ion-img [routerLink]="['/catalog/home']" class="footer-logo" src="../assets/img/qmr-white-logo.svg"></ion-img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="win.isMobile" class="mobile-container">
    <div class="row">
      <div class="col heading">Buy</div>
      <div class="col-auto ion-text-right cursor-pointer" (click)="toggleSection('buy')">
        <span class="mdi mdi-chevron-{{section.buy ? 'up': 'down'}} grey-icon"></span>
      </div>
    </div>

    <div class="links" *ngIf="section.buy">
      <p><a>Deals</a></p>
      <p><a>iPhone</a></p>
      <p><a>iPad</a></p>
      <p><a>Samsung</a></p>
      <p><a>Apple Watch</a></p>
      <p><a>Accessories</a></p>
    </div>

    <hr>
    <div class="row">
      <div class="col heading">Contact Us</div>
      <div class="col-auto ion-text-right cursor-pointer" (click)="toggleSection('contact')">
        <span class="mdi mdi-chevron-{{section.contact ? 'up': 'down'}} grey-icon"></span>
      </div>
    </div>

    <div class="links" *ngIf="section.contact">
      <p><a>Customer Support</a></p>
      <p><a>sales@quickmobilerepair.com</a></p>
    </div>

    <hr>

    <div class="row">
      <div class="col heading">Company</div>
      <div class="col-auto ion-text-right cursor-pointer" (click)="toggleSection('company')">
        <span class="mdi mdi-chevron-{{section.company ? 'up': 'down'}} grey-icon"></span>
      </div>
    </div>

    <div class="links" *ngIf="section.company">
      <p><a>About Us</a></p>
      <p><a>Customer Services</a></p>
      <p><a>Privacy Policy</a></p>
      <p><a>Grading Details</a></p>
      <p><a>Trade</a></p>
      <p><a>Sitemap</a></p>
      <p><a>Blog</a></p>
      <p><a>Unlock Phones</a></p>
    </div>

  </div>

</div>
