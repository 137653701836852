import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CartConfigPageRoutingModule } from './cart-config-routing.module';

import { CartConfigPage } from './cart-config.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    CartConfigPageRoutingModule,
  ],
  declarations: [CartConfigPage],
})
export class CartConfigPageModule {}
